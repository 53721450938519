<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Produkty - Upravit <span class="text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
        <a-form :form="form" @submit="handleSubmit">
          <a-tabs @change="key => onTabChange(key, 'tabActiveKey')" :activeKey="tabActiveKey">
            <a-tab-pane key="main">
              <a-badge dot slot="tab">
                <a-icon type="info-circle" />
                Hlavní údaje
              </a-badge>
              <h4 class="text-black mt-4 mb-3"><strong>Nastavitelné podle jazyka</strong></h4>

              <a-form-item
                label="Název"
                :validate-status="error('phe_name') ? 'error' : ''"
                :help="error('phe_name') || ''"
              >
                <a-input spellcheck="true" placeholder="Název" v-decorator="['phe_name', {rules: [{max: 125, message: 'Název nemůže být delší než 125 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
              </a-form-item>

              <a-form-item
                label="URL adresa"
                :validate-status="error('phe_url') ? 'error' : ''"
                :help="error('phe_url') || ''"
              >
                <a-input @change="handleChangeUrl" placeholder="URL adresa" v-decorator="['phe_url', {rules: [{required: true, message: 'URL musí být vyplněna!'}]}]"/>
              </a-form-item>

              <a-form-item
                label="Varianty produktu"
              >
                <a-row>
                  <a-col :offset="2" :span="22">
                    <a-form-item
                      label="Nadpis"
                      :validate-status="isOptionTitleError() ? 'error' : ''"
                      :help="isOptionTitleError() ? error('phe_option_title') : ''"
                    >
                      <a-input placeholder="Nadpis" @change="setRequiredOptions" v-decorator="['phe_option_title', {rules: [{max: 40, message: 'Nadpis nemůže být delší než 40 znaků!'}, {required: requiredPheOptionTitle, message: 'Nadpis musí být vyplněn!' }]}]"/>
                    </a-form-item>
                    <a-form-item
                      label="Varianty"
                      :validate-status="isOptionVariantError() ? 'error' : ''"
                      :help="isOptionVariantError() ? error('poe_name') : ''"
                    >
                      <a-select
                        placeholder="Varianty"
                        mode="tags"
                        @change="setRequiredOptions"
                        v-decorator="['poe_name', {rules: [{required: requiredPoeName, message: 'Varianta musí být vyplněn!'}]}]"
                        :allowClear="true"
                        :maxTagTextLength="40"
                        :open="false"
                      ></a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form-item>

              <a-form-item
                label="Stručný popisek"
                :validate-status="error('ple_description_short') ? 'error' : ''"
                :help="error('ple_description_short') || ''"
              >
                <a-textarea
                  :autoSize="{ minRows: 4, maxRows: 10 }" placeholder="Stručný popisek" v-decorator="['ple_description_short', {rules: [{max: 200, message: 'Stručný popisek nemůže být delší než 200 znaků!'}]}]"/>
              </a-form-item>

              <a-form-item
                label="Popisek"
                :validate-status="error('ple_description_long') ? 'error' : ''"
                :help="error('ple_description_long') || ''"
              >
                <ckeditor @ready="initCKEditor" :editor="editor" :config="editorConfig" class="editor" v-model="editorContent"></ckeditor>
                <a-input class="d-none" v-decorator="['ple_description_long']"></a-input>
              </a-form-item>

              <h4 class="text-black mt-4 mb-3"><strong>Ostatní</strong></h4>

              <a-form-item
                label="Cena bez DPH [Kč]"
                :validate-status="error('phy_price_no_vat') ? 'error' : ''"
                :help="error('phy_price_no_vat') || ''"
              >
                <a-input-number
                  :min="0.00"
                  :step="0.01"
                  :precision="2"
                  placeholder="Cena bez DPH [Kč]"
                  style="width: 100%;"
                  @change="calculateVat"
                  v-decorator="[`phy_price_no_vat`, { rules: [{ required: true, message: 'Cena bez DPH [Kč] musí být vyplněn!' }]}]"
                />
              </a-form-item>

              <a-form-item
                label="DPH [%]"
                :validate-status="error('vat') ? 'error' : ''"
                :help="error('vat') || ''"
              >
                <a-select
                  placeholder="-- Vyber DPH --"
                  :options="vats.map(x => { return { value: x, title: x, label: x, key: x } })"
                  v-decorator="['vat', { initialValue: vats[0], rules: [{ required: true, message: 'DPH musí být vyplněné!' }] }]"
                  @change="changeVat"
                ></a-select>
              </a-form-item>

              <a-form-item
                label="Cena s DPH [Kč]"
                :validate-status="error('phy_price_vat') ? 'error' : ''"
                :help="error('phy_price_vat') || ''"
              >
                <a-input-number
                  :min="0.00"
                  :step="0.01"
                  :precision="2"
                  placeholder="Cena s DPH [Kč]"
                  style="width: 100%;"
                  @change="calculateNoVat"
                  v-decorator="[`phy_price_vat`, { rules: [{ required: true, message: 'Cena s DPH [Kč] musí být vyplněn!' }]}]"
                />
              </a-form-item>

              <a-form-item
                :validate-status="error('put_availability') ? 'error' : ''"
                :help="error('put_availability') || ''"
              >
                <span slot="label">
                  Dostupnost
                  <a-tooltip title="-1 = není skladem | 0 = na skladě | 1-99 = za kolik dní bude na skladě.">
                    <a-icon type="question-circle-o"/>
                  </a-tooltip>
                </span>
                <a-input-number
                  :min="-1"
                  :step="1"
                  :precisison="0"
                  placeholder="Dostupnost"
                  style="width: 100%;"
                  v-decorator="[`put_availability`, { rules: [{ required: true, message: 'Dostupnost musí být vyplněn!' }]}]"
                />
              </a-form-item>
              <a-form-item label="Zveřejnit?">
                <a-checkbox v-decorator="['put_is_active', { valuePropName: 'checked', rules: [{ required: true, message: 'Zveřejnit musí být vyplněn!' }] }]"/>
              </a-form-item>
              <a-form-item label="Prodejné?">
                <a-checkbox v-decorator="['sellable', { valuePropName: 'checked', initialValue: true, rules: [{ required: true, message: 'Prodejné musí být vyplněn!' }] }]"/>
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="categories">
              <a-badge dot slot="tab">
                <a-icon type="bars" />
                Kategorie
              </a-badge>
              <a-form-item>
                <a-tree
                  :treeData="item.categories.treeData"
                  :checkable="true"
                  :selectedKeys="item.categories.selectedKeys"
                  :checkedKeys="item.categories.checkedKeys"
                  @select="onCategorySelect"
                  @check="onCategoryCheck"
                  :checkStrictly="true"
                >
                </a-tree>
                <a-empty v-if="item.categories.treeData.length === 0" >
                  <a-spin v-if="item.categories.loading" />
                </a-empty>
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="parameters">
              <a-badge dot slot="tab">
                <ion-icon class="anticon hydrated" name="funnel-outline"></ion-icon>
                Parametry
              </a-badge>
              <a-form-item
                label="Hmotnost [g]"
                :validate-status="error('phy_weight') ? 'error' : ''"
                :help="error('phy_weight') || ''"
                :required="true"
              >
                <a-input-number
                  :min="0"
                  :step="1"
                  :precision="0"
                  placeholder="Hmotnost [g]"
                  style="width: 100%;"
                  v-decorator="[`phy_weight`, { rules: [{ required: true, message: 'Hmotnost [g] musí být vyplněn!' }]}]"
                />
              </a-form-item>
              <a-form-item
                label="Minimální počet kusů"
                :validate-status="error('min_quantity') ? 'error' : ''"
                :help="error('min_quantity') || ''"
                :required="true"
              >
                <a-input-number
                  :min="1"
                  :step="1"
                  :precision="0"
                  placeholder="Minimální počet kusů"
                  style="width: 100%;"
                  v-decorator="[`min_quantity`, { rules: [{ required: true, message: 'Minimální počet kusů musí být vyplněn!' }]}]"
                />
              </a-form-item>
              <a-form-item
                label="EAN"
                :validate-status="error('put_ean') ? 'error' : ''"
                :help="error('put_ean') || ''"
              >
                <a-input placeholder="EAN" v-decorator="['put_ean', {rules: [{max: 20, message: 'EAN nemůže být delší než 20 znaků!'}]}]"/>
              </a-form-item>
              <a-form-item
                label="Kód"
                :validate-status="error('put_code') ? 'error' : ''"
                :help="error('put_code') || ''"
              >
                <a-input placeholder="Kód" v-decorator="['put_code', {rules: [{max: 100, message: 'Kód nemůže být delší než 100 znaků!'}]}]"/>
              </a-form-item>
              <a-form-item
                label="Záruka [měsíc]"
                :validate-status="error('put_warranty') ? 'error' : ''"
                :help="error('put_warranty') || ''"
              >
                <a-input-number
                  :min="0"
                  :step="1"
                  :precision="0"
                  placeholder="Záruka [měsíc]"
                  style="width: 100%;"
                  v-decorator="[`put_warranty`]"
                />
              </a-form-item>
              <a-form-item
                label="Výrobce"
                :validate-status="error('mur_id') ? 'error' : ''"
                :help="error('mur_id') || ''"
              >
                <a-select
                  placeholder="-- Vyber výrobce --"
                  :loading="item.manufacturers.loading"
                  :options="manufacturers.map(x => { return { value: x.mur_id.toString(), label: '#' + x.mur_id + ' | ' + x.mur_name } })"
                  v-decorator="['mur_id']"
                  :allowClear="true"
                ></a-select>
              </a-form-item>
              <a-form-item
                label="Parametry"
                :validate-status="error('parameters') ? 'error' : ''"
                :help="error('parameters') || ''"
              >
                <a-tree-select
                  :allowClear="true"
                  :multiple="true"
                  placeholder="-- Vyber parametry a jejich hodnoty --"
                  :treeData="item.parameters.treeData"
                  :loading="item.parameters.loading"
                  v-decorator="['parameters']"
                ></a-tree-select>
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="groups">
              <a-badge dot slot="tab">
                <a-icon type="percentage" />
                Slevy
              </a-badge>
              <my-group
                :error="error"
                :public-groups="publicGroups"
                :private-groups="privateGroups"
                :private-groups-object="mPrivateGroups"
                :public-groups-object="mPublicGroups"
                :load-private-groups="loadPrivateGroups"
                :show-private-groups="showPrivateGroups"
                :hide-private-groups="hidePrivateGroups"
                :language="language"
                :get-private-group-initial-value="getPrivateGroupInitialValue"
                :discount-name="discountName"
              ></my-group>

            </a-tab-pane>
            <a-tab-pane key="deliveries">
              <span slot="tab">
                <a-icon type="car" />
                Doprava
              </span>
              <a-table
                :rowKey="record => record.dly_id"
                :columns="item.deliveries.columns"
                :loading="item.deliveries.loading"
                :dataSource="deliveries"
                :pagination="deliveries.length > this.$pageSize ? { pageSize: this.$pageSize } : false"
                class="utils__scrollTable"
                :scroll="{ x: '100%' }"
              >
                <router-link
                  slot="dly_id"
                  slot-scope="value"
                  class="utils__link--underlined"
                  :to="'/ecommerce/delivery/detail/' + value"
                >#{{value}}</router-link>
                <span
                  slot="hide"
                  slot-scope="value"
                >
                  <a-form-item
                    label=""
                    class="m-0"
                  >
                    <a-checkbox
                      @change="() => { item.deliveries.pdy_fixed_price[value.dly_id] = undefined }"
                      v-model="item.deliveries.pdy_always_hide[value.dly_id]"
                    />
                  </a-form-item>
                </span>
                <template slot="customRender" slot-scope="text">
                  <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
                  <span v-else>{{text}}</span>
                </template>
                <span
                  slot="price"
                  slot-scope="value"
                >
                  <a-form-item
                    class="m-0"
                    label="">
                    <a-input-number
                      :disabled="item.deliveries.pdy_always_hide[value.dly_id] !== undefined && item.deliveries.pdy_always_hide[value.dly_id] === true"
                      :min="0.00"
                      :step="0.01"
                      :precision="2"
                      style="width: 100%;"
                      v-model="item.deliveries.pdy_fixed_price[value.dly_id]"
                    />
                  </a-form-item>
                </span>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="feeds">
              <a-badge slot="tab">
                <ion-icon class="anticon hydrated" name="cloud-download-outline"></ion-icon>
                Feedy
              </a-badge>

              <a-tabs @change="key => onTabFeedChange(key, 'tabFeedActiveKey')" :activeKey="tabFeedActiveKey">
                <a-tab-pane key="import">
                  <a-badge slot="tab">
                    <ion-icon class="anticon hydrated" name="cloud-download-outline"></ion-icon>
                    Import
                  </a-badge>

                  <a-empty v-if="feeds.length === 0">
                    <a-spin v-if="item.feeds.loading" />
                  </a-empty>

                  <a-row v-else type="flex" justify="start" :gutter="30">
                    <a-col :xl="6" :md="8" :sm="12" :xs="24" v-for="fc in feeds" :key="'fc'+fc.id">
                      <a-card style="border-radius: 4px;">
                        <div class="utils__title">{{ fc.name }}</div>
                        <a-form-item
                          label="Identifikační hodnota ve feedu"
                          :validate-status="error('identifier['+fc.id+']') ? 'error' : ''"
                          :help="error('identifier['+fc.id+']') || ''"
                        >
                          <a-input placeholder="Identifikační hodnota ve feedu"
                                   v-decorator="['identifier['+fc.id+']', {rules: [{max: 100, message: 'Identifikační hodnota ve feedu nemůže být delší než 100 znaků!'}, {required: form.getFieldValue('sync['+fc.id+']') !== undefined && form.getFieldValue('sync['+fc.id+']') , message: 'Identifikační hodnota ve feedu musí být vyplněna!'}]}]" />
                        </a-form-item>
                        <a-form-item
                          label="Synchronizovat"
                        >
                          <a-checkbox
                            v-decorator="['sync['+fc.id+']', { valuePropName: 'checked', initialValue: false }]"></a-checkbox>
                        </a-form-item>
                      </a-card>
                    </a-col>
                  </a-row>
                </a-tab-pane>
                <a-tab-pane key="export">
                  <a-badge slot="tab">
                    <ion-icon class="anticon hydrated" name="cloud-upload-outline"></ion-icon>
                    Export
                  </a-badge>

                  <a-tabs>
                    <a-tab-pane key="google">
                      <a-badge slot="tab">
                        <ion-icon class="anticon hydrated" name="logo-google"></ion-icon>
                        Google
                      </a-badge>

                      <a-tabs>
                        <a-tab-pane key="category">
                          <a-badge slot="tab">
                            <a-icon type="bars" />
                            Kategorie
                          </a-badge>

                          <a-row>
                            <h4 class="text-black mt-4 mb-3"><strong>Vybraná kategorie</strong></h4>

                            <a-empty v-if="item.exportFeeds.google.categories.selectedObj === null"></a-empty>
                            <div v-else>
                              <a-table
                                tableLayout="auto"
                                :rowKey="record => record.id"
                                :columns="item.exportFeeds.google.categories.columns"
                                class="utils__scrollTable"
                                :scroll="{ x: '100%' }"
                                :dataSource="item.exportFeeds.google.categories.selectedObj === null ? [] : [item.exportFeeds.google.categories.selectedObj]"
                              >
                                <span slot="dates" slot-scope="text">{{ $options.filters.formatDate(text) }}</span>
                              </a-table>
                            </div>
                          </a-row>
                          <a-row>
                            <h4 class="text-black mt-4 mb-3"><strong>Seznam kategorií</strong></h4>

                            <a-form-item
                              label="Vybrat"
                              class="mb-2"
                            >
                              <a-switch v-model="item.exportFeeds.google.categories.switch"></a-switch>
                            </a-form-item>

                            <my-table
                              :disabled="!item.exportFeeds.google.categories.switch"
                              v-on:changeSelectedRowKeys="changeSelectedGoogleCategory"
                              v-on:fetch="fetchGoogleCategory"
                              :select-multiple="false"
                              default-order="asc"
                              :loading="loading"
                              :columns="item.exportFeeds.google.categories.columns"
                              :data-source="googleCategories"
                              id-name="id"
                              :table-name="item.exportFeeds.google.categories.tableName"
                            >
                            </my-table>
                          </a-row>
                        </a-tab-pane>
                      </a-tabs>
                    </a-tab-pane>
                  </a-tabs>
                </a-tab-pane>
              </a-tabs>
            </a-tab-pane>
            <a-tab-pane key="images">
              <span slot="tab">
                <a-icon type="picture" />
                Obrázky
              </span>
              <my-form-image-item
                title="Obrázky"
                subject="Produkt"
                decorator-name="imageSelected"
                :image="thumbImage"
                :image-next-count="thumbImageNextCount"
                :images="thumbImages"
                :on-image-switch-change="onThumbImageSwitchChange"
                :on-next-images-click="onNextThumbImagesClick"
                :form="form"
                type="product"
              >
              </my-form-image-item>
            </a-tab-pane>
            <a-tab-pane key="payments">
              <span slot="tab">
                <a-icon type="credit-card" />
                Platba
              </span>
              <a-table
                :rowKey="record => record.pmt_id"
                :columns="item.payments.columns"
                :loading="item.payments.loading"
                :dataSource="payments"
                :pagination="payments.length > this.$pageSize ? { pageSize: this.$pageSize } : false"
                class="utils__scrollTable"
                :scroll="{ x: '100%' }"
              >
                <router-link
                  slot="pmt_id"
                  slot-scope="value"
                  class="utils__link--underlined"
                  :to="'/ecommerce/payment/detail/' + value"
                >#{{ value }}</router-link>
                <span
                  slot="enable"
                  slot-scope="value">
                  <a-form-item
                    label=""
                    class="m-0"
                  >
                    <a-checkbox v-model="item.payments.payments[value.pmt_id]"></a-checkbox>
                  </a-form-item>
                </span>
                <template slot="customRender" slot-scope="text">
                  <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
                  <span v-else>{{text}}</span>
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="similar">
              <span slot="tab">
                <ion-icon class="anticon hydrated" name="logo-apple" />
                Podobné produkty
              </span>
              <a-row>
                <h4 class="text-black mt-4 mb-3"><strong>Vybrané podobné produkty ({{ item.similar.selected.length }})</strong></h4>
                <a-list :grid="{ gutter: 16, xs: 2, sm: 2, md: 4, lg: 4, xl: 4, xxl: 6 }" :data-source="item.similar.selected">
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a-card class="text-center">
                      <a-alert v-if="item.history_languages === null || item.history_languages.length === 0" type="warning" :message="'#' + item.put_id + ' Název není nastaven'" :showIcon="true"></a-alert>
                      <template v-else>
                        #{{ item.put_id + ' ' + item.history_languages[0].pivot.phe_name }}
                      </template>
                      <a-checkbox class="mt-2 d-block" v-model="item.selected" @change="onProductSimilarChange(item)"></a-checkbox>
                    </a-card>
                  </a-list-item>
                </a-list>
              </a-row>
              <a-row>
                <h4 class="text-black mt-4 mb-3"><strong>Seznam produktů</strong></h4>
                <a-col :span="8" :offset="8">
                  <a-tree-select
                    style="display:block;margin-bottom: 1rem"
                    :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                    :treeData="item.similar.categories.treeData"
                    placeholder="-- Vyber kategorii --"
                    @change="this.changeCategory"
                    :value="item.similar.categories.selected"
                    :loading="item.categories.loading"
                  >
                  </a-tree-select>
                </a-col>
                <a-col :span="8" style="text-align:right;">
                  <a-input-search
                    placeholder="Hledat..."
                    style="width: 200px;"
                    @search="handleProductSearch"
                  />
                </a-col>
              </a-row>
              <a-table
                tableLayout="auto"
                :rowSelection="productRowSelection"
                :rowKey="record => record.put_id"
                :columns="item.similar.products.columns"
                :loading="item.similar.products.loading"
                :dataSource="product"
                class="utils__scrollTable"
                :scroll="{ x: '100%' }"
              >
                <router-link
                  slot="put_id"
                  slot-scope="value"
                  class="utils__link--underlined"
                  :to="'/ecommerce/product/detail/' + value"
                >#<span v-if="item.similar.products.searchText">
                    <template
                      v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${item.similar.products.searchText})|(?=${item.similar.products.searchText})`, 'i'))">
                      <mark
                        v-if="fragment.toLowerCase() === item.similar.products.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                      >{{fragment}}</mark>
                      <template v-else>{{fragment}}</template>
                    </template>
                  </span>
                  <template v-else>{{value}}</template>
                </router-link>
                <template slot="customRender" slot-scope="text">
                  <a-alert v-if="text === undefined || text === null" message="není nastaveno" type="warning" showIcon />
                  <span v-else-if="item.similar.products.searchText">
                    <template
                      v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${item.similar.products.searchText})|(?=${item.similar.products.searchText})`, 'i'))">
                      <mark
                        v-if="fragment.toLowerCase() === item.similar.products.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                      >{{fragment}}</mark>
                      <template v-else>{{fragment}}</template>
                    </template>
                  </span>
                  <template v-else>{{text}}</template>
                </template>
                <span
                  slot="active"
                  slot-scope="value"
                  :class="[value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
                >
                  <span v-if="item.similar.products.searchText">
                    <template
                      v-for="(fragment, i) in (value ? 'ano' : 'ne').toString().split(new RegExp(`(?<=${item.similar.products.searchText})|(?=${item.similar.products.searchText})`, 'i'))">
                      <mark
                        v-if="fragment.toLowerCase() === item.similar.products.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                      >{{fragment}}</mark>
                      <template v-else>{{fragment}}</template>
                    </template>
                  </span>
                  <template v-else>{{value ? 'ano' : 'ne'}}</template>
                </span>
                <template slot="footer">
                  {{ product.length }} položek
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="seo">
              <span slot="tab">
                <a-icon type="global" />
                SEO
              </span>
              <a-form-item
                label="Meta keywords"
                :validate-status="error('meta_keywords') ? 'error' : ''"
                :help="error('meta_keywords') || ''"
              >
                <a-select placeholder="-- Vyber meta keywords --" :loading="item.meta_keywords.loading" mode="tags" :options="metaKeywords.map(x => { return { value: x.mkd_id.toString(), label: x.languages.length > 0 ? '# ' + x.mkd_id + ' | ' + x.languages[0].pivot.mkd_name : '# ' + x.mkd_id + ' | není nastaveno' } })" v-decorator="['meta_keywords']"></a-select>
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="files">
              <a-row>
                <h4 class="text-black mt-4 mb-3"><strong>Vybrané soubory ({{ item.files.selected.length }})</strong></h4>
                <a-list :grid="{ gutter: 16, xs: 2, sm: 2, md: 4, lg: 4, xl: 4, xxl: 6 }" :data-source="item.files.selected">
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a-card class="text-center">
                      #{{ item.fie_id + ' | ' + item.fie_name + '.' + item.fie_format }}
                      <a-checkbox class="mt-2 d-block" v-model="item.selected" @change="onFileChange(item)"></a-checkbox>
                    </a-card>
                  </a-list-item>
                </a-list>
              </a-row>
              <span slot="tab">
                <a-icon type="file-pdf" />
                Soubory
              </span>
              <div class="row mb-3">
                <div class="text-right col-md-12">
                  <a-input-search
                    placeholder="Hledat..."
                    style="width: 200px"
                    @search="handleFileSearch"
                  />
                </div>
              </div>
              <a-table
                :rowSelection="fileRowSelection"
                :rowKey="record => record.fie_id"
                :columns="item.files.columns"
                :loading="item.files.loading"
                :dataSource="files"
                class="utils__scrollTable"
                :scroll="{ x: '100%' }"
              >
                <router-link
                  slot="fie_id"
                  slot-scope="value"
                  class="utils__link--underlined"
                  :to="'/file/detail/' + value"
                >#<span v-if="item.files.searchText">
                    <template
                      v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${item.files.searchText})|(?=${item.files.searchText})`, 'i'))">
                      <mark
                        v-if="fragment.toLowerCase() === item.files.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                      >{{fragment}}</mark>
                      <template v-else>{{fragment}}</template>
                    </template>
                  </span>
                  <template v-else>{{value}}</template>
                </router-link>
                <template slot="customRender" slot-scope="text">
                  <span v-if="item.files.searchText">
                    <template
                      v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${item.files.searchText})|(?=${item.files.searchText})`, 'i'))">
                      <mark
                        v-if="fragment.toLowerCase() === item.files.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                      >{{fragment}}</mark>
                      <template v-else>{{fragment}}</template>
                    </template>
                  </span>
                  <template v-else>{{text}}</template>
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="stickers">
              <span slot="tab">
                <a-icon type="tags" />
                Štítky
              </span>
              <a-form-item label="Štítky">
                <a-checkbox-group
                  v-if="stickers && stickers.length !== 0"
                  v-decorator="['stickers']"
                  name="stickers"
                  :options="stickers.map(x => {
                    return {
                      label: x.languages[0].pivot.label, value: x.ser_id,
                    }
                  })"
                />
                <a-empty v-else></a-empty>
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="reviews">
              <a-badge slot="tab" :count="currentDetailLang.reviews.length" class="ant-badge-count-small" :offset="[0, -4]" :numberStyle="{backgroundColor: '#52c41a'}">
                <a-icon type="star"></a-icon>
                Recenze
              </a-badge>
              <div class="mb-5 p-5 ant-card-bordered" v-if="stats.reviews !== null && stats.reviews.users_count > 0">
                <a-row type="flex" justify="center" v-if="stats.reviews !== null" :gutter="20">
                  <a-col class="text-center mr-2">
                    <h4 class="h1 text-black"><strong>{{ Math.round(parseFloat(stats.reviews.rating)*20) }} %</strong></h4>
                    <div>
                      <a-rate :value="Math.ceil(parseFloat(stats.reviews.rating)/.5)*.5" :allowHalf="true" :disabled="true"/>
                    </div>
                    <div>
                      Hodnotilo {{ stats.reviews.users_count }} uživatelů
                    </div>
                  </a-col>
                  <a-col>
                    <ul class="list-unstyled">
                      <li>
                        5
                        <a-icon class="icon-star-color" type="star" theme="filled"></a-icon>
                        {{ stats.reviews.five_star_count }}x
                      </li>
                      <li>
                        4
                        <a-icon class="icon-star-color" type="star" theme="filled"></a-icon>
                        {{ stats.reviews.four_star_count }}x
                      </li>
                      <li>
                        3
                        <a-icon class="icon-star-color" type="star" theme="filled"></a-icon>
                        {{ stats.reviews.three_star_count }}x
                      </li>
                      <li>
                        2
                        <a-icon class="icon-star-color" type="star" theme="filled"></a-icon>
                        {{ stats.reviews.two_star_count }}x
                      </li>
                      <li>
                        1
                        <a-icon class="icon-star-color" type="star" theme="filled"></a-icon>
                        {{ stats.reviews.one_star_count }}x
                      </li>
                    </ul>
                  </a-col>
                </a-row>
              </div>
              <a-list
                class="list-tree"
                itemLayout="vertical"
                size="large"
                :pagination="false"
                v-if="reviewId && activeReview"
                :dataSource="[activeReview]"
              >
                <a-list-item slot-scope="item" :key="item.rvw_id" v-if="item.put_id !== Number.parseInt(id)" slot="renderItem">
                  <a-alert showIcon message="Error!" type="error">
                    <div slot="description">
                      Recenze nepatří této položce! Pro zobrazení recenze klikněte <router-link class="utils__link--underlined utils__link" :to="{ name: 'product.detail.reviews.detail', params: { id: item.put_id, reviewId: item.rvw_id } }">zde</router-link>.
                    </div>
                  </a-alert>
                </a-list-item>
                <a-list-item ref="activeReview" :class="highlightReview ? 'list-item-highlight' : ''" v-else-if="item.deleted === undefined" slot="renderItem" slot-scope="item" :key="item.rvw_id">
                  <div slot="extra">
                    <a-dropdown>
                      <a-button icon="down">Akce</a-button>
                      <a-menu slot="overlay">
                        <a-menu-item>
                          <a-popconfirm @confirm="deleteReview(item)">
                            <template slot="title">
                              <p>Opravdu si přejete odstranit tuto položku?</p>
                            </template>
                            <a-icon type="delete" class="mr-2"/>Odstranit
                          </a-popconfirm>
                        </a-menu-item>
                        <a-menu-item v-if="item.user.banned_to === null && item.user.uer_type !== 'AMR'" @click="clickShowModal(item)">
                          <a-icon type="stop" class="mr-2"/>Zabanovat uživatele
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </div>
                  <a-row type="flex" justify="start" :gutter="30">
                    <a-col :lg="6" :xs="24">
                      <div class="list-avatar-user text-center">
                        <div>
                          <cui-avatar
                            src="/resources/images/avatar.jpg"
                            :size="50"
                            :link="item.user.uer_type === 'AMR' ? '/user/admin/detail/' + item.user.uer_id : item.user.uer_type === 'RID' ? '/user/registered/detail/' + item.user.uer_id : ''"
                            :bordered="false"
                          />
                        </div>
                        <strong>{{item.user.email === null ? item.user.ip_addresses.length === 0 ? item.user.uer_session_id : item.user.ip_addresses[0].ias_ip_address : item.user.email}}</strong>
                        <br>
                        <small class="tet-muted">{{$options.filters.formatDate(item.created_at)}}</small>
                        <div>
                          <a-rate :value="parseFloat(item.rvw_rating)" :allowHalf="true" :disabled="true"/>
                        </div>
                      </div>
                    </a-col>
                    <a-col :lg="18" :xs="24" class="list-body-with-actions">
                      <div v-if="item.rvw_title !== null">
                        <div class="ant-list-item-meta-title">{{ item.rvw_title }}</div>
                      </div>
                      <div v-if="item.rvw_message !== null" class="mt-4">
                        {{item.rvw_message}}
                      </div>
                      <a-row v-if="item.rvw_pros !== null || item.rvw_cons !== null" class="mt-3">
                        <a-col :span="12" v-if="item.rvw_pros !== null">
                          <ul class="list-unstyled">
                            <li v-for="(pro, index) in JSON.parse(item.rvw_pros)" :key="'pro'+index">
                              <a-icon type="plus-circle" theme="twoTone" twoToneColor="#52c41a"></a-icon>
                              {{ pro.item }}
                            </li>
                          </ul>
                        </a-col>
                        <a-col :span="12" v-if="item.rvw_cons !== null">
                          <ul class="list-unstyled">
                            <li v-for="(con, index) in JSON.parse(item.rvw_cons)" :key="'con'+index">
                              <a-icon type="minus-circle" theme="twoTone" twoToneColor="#eb2f96"></a-icon>
                              {{ con.item }}
                            </li>
                          </ul>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </a-list-item>
              </a-list>
              <a-list
                itemLayout="vertical"
                size="large"
                :pagination="currentDetailLang.reviews.length > reviews.pagination.pageSize ? reviews.pagination : false"
                :dataSource="currentDetailLang.reviews"
              >
                <a-list-item v-if="item.deleted === undefined && (reviewId === undefined || item.rvw_id !== Number.parseInt(reviewId))" slot="renderItem" slot-scope="item" :key="item.rvw_id">
                  <div slot="extra">
                    <a-dropdown>
                      <a-button icon="down">Akce</a-button>
                      <a-menu slot="overlay">
                        <a-menu-item>
                          <a-popconfirm @confirm="deleteReview(item)">
                            <template slot="title">
                              <p>Opravdu si přejete odstranit tuto položku?</p>
                            </template>
                            <a-icon type="delete" class="mr-2"/>Odstranit
                          </a-popconfirm>
                        </a-menu-item>
                        <a-menu-item v-if="item.user.banned_to === null && item.user.uer_type !== 'AMR'" @click="clickShowModal(item)">
                          <a-icon type="stop" class="mr-2"/>Zabanovat uživatele
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </div>
                  <a-row type="flex" justify="start" :gutter="30">
                    <a-col :lg="6" :xs="24">
                      <div class="list-avatar-user text-center">
                        <div>
                          <cui-avatar
                            src="/resources/images/avatar.jpg"
                            :size="50"
                            :link="item.user.uer_type === 'AMR' ? '/user/admin/detail/' + item.user.uer_id : item.user.uer_type === 'RID' ? '/user/registered/detail/' + item.user.uer_id : ''"
                            :bordered="false"
                          />
                        </div>
                        <strong>{{item.user.email === null ? item.user.ip_addresses.length === 0 ? item.user.uer_session_id : item.user.ip_addresses[0].ias_ip_address : item.user.email}}</strong>
                        <br>
                        <small class="tet-muted">{{$options.filters.formatDate(item.created_at)}}</small>
                        <div>
                          <a-rate :value="parseFloat(item.rvw_rating)" :allowHalf="true" :disabled="true"/>
                        </div>
                      </div>
                    </a-col>
                    <a-col :lg="18" :xs="24" class="list-body-with-actions">
                      <div v-if="item.rvw_title !== null">
                        <div class="ant-list-item-meta-title">{{ item.rvw_title }}</div>
                      </div>
                      <div v-if="item.rvw_message !== null" class="mt-4">
                        {{item.rvw_message}}
                      </div>
                      <a-row v-if="item.rvw_pros !== null || item.rvw_cons !== null" class="mt-3">
                        <a-col :span="12" v-if="item.rvw_pros !== null">
                          <ul class="list-unstyled">
                            <li v-for="(pro, index) in JSON.parse(item.rvw_pros)" :key="'pro'+index">
                              <a-icon type="plus-circle" theme="twoTone" twoToneColor="#52c41a"></a-icon>
                              {{ pro.item }}
                            </li>
                          </ul>
                        </a-col>
                        <a-col :span="12" v-if="item.rvw_cons !== null">
                          <ul class="list-unstyled">
                            <li v-for="(con, index) in JSON.parse(item.rvw_cons)" :key="'con'+index">
                              <a-icon type="minus-circle" theme="twoTone" twoToneColor="#eb2f96"></a-icon>
                              {{ con.item }}
                            </li>
                          </ul>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </a-list-item>
              </a-list>
            </a-tab-pane>
            <a-tab-pane key="comments">
              <a-badge slot="tab" :count="currentDetailLang.comments.length" class="ant-badge-count-small" :offset="[0, -4]" :numberStyle="{backgroundColor: '#52c41a'}">
                <a-icon type="message"></a-icon>
                Komentáře
              </a-badge>
              <list-tree
                :activeCommentId="Number.parseInt(commentId)"
                :tree-data="currentDetailLang.comments"
                :pagination="reviews.pagination"
                :loading="loading"
                :quoteParent="false"
                :is-user-banned="isUserBanned"
                :delete-comment="deleteComment"
                :click-show-modal="clickShowModal"
                :reply-to-comment="replyToComment"
                :update-comment="updateComment"
                :load-children-replies="loadChildrenReplies"
              ></list-tree>
            </a-tab-pane>
          </a-tabs>
          <div class="form-actions">
            <a-button html-type="submit" type="primary" :disabled="isFormInvalid()">Upravit</a-button>
          </div>
        </a-form>
      </div>
    </div>
    <a-modal title="Zabanovat na X dní" v-model="visible" @ok="banUser(visibleId, daysBanTo, visibleUser)">
      <template slot="footer">
        <a-button key="back" @click="() => this.visible = false">Storno</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="banUser(visibleId, daysBanTo, visibleUser)">
          Ok
        </a-button>
      </template>
      <a-input-number placeholder="Zabanovat na X dní" :min="1" :defaultValue="1" v-model="daysBanTo"></a-input-number>
    </a-modal>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import editorMixin from '@/services/editorMixin'
import ActionTools from '@/components/MyComponents/ActionTools'
import CuiAvatar from '@/components/CleanUIComponents/Avatar'
import ListTree from '@/components/MyComponents/Comments/ListTree'
import { hasErrors, hasErrorsExcept } from '../../../../services/forms'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'
import myFormImageItem from '@/components/MyComponents/Gallery/my-form-image-item'
import thumbImageMixin from '@/components/MyComponents/Gallery/thumb-image-mixin'
import myGroup from '@/components/MyComponents/Groups/my-group'
import groupMixin from '@/components/MyComponents/Groups/group-mixin'
import inputCategoryUrlMixin from '@/services/inputCategoryUrlMixin'
import MyTable from '@/components/MyComponents/Table/my-table'

function typeOfNaN(x) {
  return Number.isNaN(x) || isNaN(x)
}

export default {
  components: {
    ActionTools, LanguageTab, CuiAvatar, ListTree, myFormImageItem, myGroup, MyTable,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  mixins: [imageSizeMixin, thumbImageMixin, groupMixin, inputCategoryUrlMixin, editorMixin],
  data() {
    return {
      tabFeedActiveKey: 'import',
      discountName: 'pgt_discount',
      commentId: this.$route.params.commentId,
      reviewId: this.$route.params.reviewId,
      visible: null,
      visibleId: null,
      visibleUser: null,
      daysBanTo: 1,
      id: this.$route.params.id,
      hasErrors,
      visitedTabs: new Set(),
      reviews: {
        pagination: {
          pageSize: 10,
        },
      },
      loading: false,
      dependencies: ['parameters', 'manufacturers', 'files', 'categories', 'meta_keywords', 'payments', 'deliveries'],
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/product/',
          title: 'Seznam',
        },
      ],
      item: {
        exportFeeds: {
          google: {
            categories: {
              loading: false,
              switch: false,
              selected: [],
              selectedObj: null,
              tableName: 'google_feed_categories',
              columns: [
                {
                  title: 'ID',
                  dataIndex: 'id',
                  defaultSortOrder: 'ascend',
                  sorter: true,
                  scopedSlots: {
                    customRender: 'customRender',
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                  },
                },
                {
                  title: 'Google id',
                  dataIndex: 'google_id',
                  sorter: true,
                  scopedSlots: {
                    customRender: 'customRender',
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                  },
                },
                {
                  title: 'Kategorie',
                  dataIndex: 'name',
                  sorter: true,
                  scopedSlots: {
                    customRender: 'customRender',
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                  },
                },
                {
                  title: 'Vytvořeno',
                  dataIndex: 'created_at',
                  pattern: '[ROK-MĚSÍC-DEN] 2021-01-07',
                  sorter: true,
                  scopedSlots: {
                    customRender: 'dates',
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                  },
                },
                {
                  title: 'Poslední změna',
                  dataIndex: 'updated_at',
                  pattern: '[ROK-MĚSÍC-DEN] 2021-01-07',
                  sorter: true,
                  scopedSlots: {
                    customRender: 'dates',
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                  },
                },
              ],
            },
          },
        },
        feeds: {
          loaded: false,
          loading: false,
        },
        stickers: {
          loading: false,
          loaded: [],
          dispatch: 'sticker/getList',
        },
        similar: {
          categories: {
            treeData: [],
            selected: undefined,
          },
          selected: [],
          products: {
            selectedRowKeys: [],
            selectedItems: [],
            searchText: '',
            loaded: [],
            loading: false,
            columns: [
              {
                title: 'ID',
                dataIndex: 'put_id',
                defaultSortOrder: 'ascend',
                sorter: (a, b) => a.put_id - b.put_id,
                scopedSlots: {
                  customRender: 'put_id',
                },
              },
              {
                title: 'Kód',
                dataIndex: 'put_code',
                sorter: (a, b) => {
                  return a.put_code + ''.localeCompare(b.put_code + '')
                },
                scopedSlots: {
                  customRender: 'customRender',
                },
              },
              {
                title: 'Název',
                dataIndex: 'history_languages[0].pivot.phe_name',
                sorter: (a, b) => {
                  return a.history_languages[0].pivot.phe_name.localeCompare(b.history_languages[0].pivot.phe_name)
                },
                scopedSlots: {
                  customRender: 'customRender',
                },
              },
              {
                title: 'Zveřejněn',
                dataIndex: 'put_is_active',
                sorter: (a, b) => a.put_is_active - b.put_is_active,
                scopedSlots: {
                  customRender: 'active',
                },
              },
            ],
          },
        },
        languages: [],
        history: {},
        history_languages: [],
        options: [],
        categories: {
          loading: false,
          treeData: [],
          checkedKeys: {
            checked: [],
          },
          fields: [],
          loaded: [],
          lang: true,
          selectedKeys: [],
          dispatch: 'category/getList',
        },
        manufacturers: {
          loading: false,
          lang: false,
          loaded: [],
          active: null,
        },
        files: {
          loading: false,
          selectedItems: [],
          loaded: [],
          selected: [],
          lang: false,
          searchText: '',
          selectedRowKeys: [],
          columns: [
            {
              title: 'ID',
              dataIndex: 'fie_id',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => a.fie_id - b.fie_id,
              scopedSlots: {
                customRender: 'fie_id',
              },
            },
            {
              title: 'Název',
              dataIndex: 'fie_name',
              sorter: (a, b) => {
                return a.fie_name.localeCompare(b.fie_name)
              },
              scopedSlots: {
                customRender: 'customRender',
              },
            },
            {
              title: 'Formát',
              dataIndex: 'fie_format',
              sorter: (a, b) => {
                return a.fie_format.localeCompare(b.fie_format)
              },
              scopedSlots: {
                customRender: 'customRender',
              },
            },
            {
              title: 'Kategorie',
              dataIndex: 'file_category.fcy_name',
              sorter: (a, b) => {
                return a.file_category.fcy_name.localeCompare(b.file_category.fcy_name)
              },
              scopedSlots: {
                customRender: 'customRender',
              },
            },
          ],
          dispatch: 'file/getList',
        },
        parameters: {
          loading: false,
          lang: true,
          loaded: [],
          treeData: [],
          active: [],
        },
        payments: {
          fields: [{
            itemName: 'payments',
            fieldName: 'payments',
          }],
          dispatch: 'payment/getList',
          loading: false,
          payments: [],
          active: [],
          lang: true,
          loaded: [],
          columns: [
            {
              title: 'ID',
              dataIndex: 'pmt_id',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => a.pmt_id - b.pmt_id,
              scopedSlots: {
                customRender: 'pmt_id',
              },
            },
            {
              title: 'Název',
              dataIndex: 'current_history.languages[0].phe_name',
              sorter: (a, b) => {
                return a.current_history.languages[0].phe_name.localeCompare(b.current_history.languages[0].phe_name)
              },
              scopedSlots: {
                customRender: 'customRender',
              },
            },
            {
              title: 'Zobrazit',
              dataIndex: '',
              scopedSlots: {
                customRender: 'enable',
              },
            },
          ],
        },
        meta_keywords: {
          loaded: [],
          lang: true,
          loading: false,
          active: [],
        },
        deliveries: {
          fields: [{
            itemName: 'deliveries',
            fieldName: 'pdy_always_hide',
          }, {
            itemName: 'deliveries',
            fieldName: 'pdy_fixed_price',
          }],
          active: [],
          pdy_fixed_price: [],
          pdy_always_hide: [],
          dispatch: 'delivery/getList',
          loaded: [],
          lang: true,
          loading: false,
          columns: [
            {
              title: 'ID',
              dataIndex: 'dly_id',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => a.dly_id - b.dly_id,
              scopedSlots: {
                customRender: 'dly_id',
              },
            },
            {
              title: 'Název',
              dataIndex: 'current_history.languages[0].pivot.dhe_name',
              sorter: (a, b) => {
                return a.current_history.languages[0].pivot.dhe_name.localeCompare(b.current_history.languages[0].pivot.dhe_name)
              },
              scopedSlots: {
                customRender: 'customRender',
              },
            },
            {
              title: 'Nikdy nezobrazit',
              dataIndex: '',
              scopedSlots: {
                customRender: 'hide',
              },
            },
            {
              title: 'Fixní cena',
              dataIndex: '',
              scopedSlots: {
                customRender: 'price',
              },
            },
          ],
        },
      },
      tabActiveKey: this.initTabActiveKey(),
      tabActiveKeys: [this.initTabActiveKey()],
      requiredPoeName: false,
      requiredPheOptionTitle: false,
      loaded: [],
      highlightReview: true,
    }
  },
  computed: {
    googleCategories() {
      return this.$store.getters['googleCategory/allItems']
    },
    feeds: function() {
      return this.$store.getters['feeds/allItems']('')
    },
    stickers: function() {
      return this.$store.getters['sticker/currentLanguage']('')
    },
    activeReview: function () {
      return this.$store.getters['review/getDetail']
    },
    productRowSelection() {
      const { selectedRowKeys } = this.item.similar.products
      return {
        selectedRowKeys,
        onChange: this.onProductSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            let found = false
            if (this.item.similar.products.selectedRowKeys.length !== 0) {
              this.product.some(y => {
                if (this.item.similar.products.selectedRowKeys.includes(y.put_id)) {
                  found = true
                  return true
                }
              })
            }
            const diff = this.item.similar.selected.filter(x => !this.product.includes(x))
            if (!found) {
              this.item.similar.products.selectedRowKeys = [...new Set(this.product.map(x => x.put_id).concat(diff.map(x => x.put_id)))]
              this.item.similar.selected = [...new Set(this.item.similar.selected.concat(this.product.map(x => { x.selected = true; return x })))]
              this.item.similar.products.selectedItems = this.item.similar.selected
            } else {
              this.item.similar.products.selectedRowKeys = diff.map(x => x.put_id)
              this.item.similar.selected = diff
              this.item.similar.products.selectedItems = this.item.similar.selected
            }
          },
        }],
        onSelection: this.onSelection,
      }
    },
    detail: function() {
      return this.$store.getters['product/getDetail']
    },
    stats: function() {
      return this.$store.getters['product/getCurrentLanguageStats']
    },
    vats: function() {
      return this.$store.getters['product/getVats']
    },
    userType: function() {
      return this.$store.getters['userAdmin/getType']('')
    },
    fileRowSelection() {
      const { selectedRowKeys } = this.item.files
      return {
        selectedRowKeys,
        onChange: this.onFileSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            let found = false
            if (this.item.files.selectedRowKeys.length !== 0) {
              this.files.some(y => {
                if (this.item.files.selectedRowKeys.includes(y.fie_id)) {
                  found = true
                  return true
                }
              })
            }
            const diff = this.item.files.selected.filter(x => !this.files.includes(x))
            if (!found) {
              this.item.files.selectedRowKeys = [...new Set(this.files.map(x => x.fie_id).concat(diff.map(x => x.fie_id)))]
              this.item.files.selected = [...new Set(this.item.files.selected.concat(this.files.map(x => { x.selected = true; return x })))]
            } else {
              this.item.files.selectedRowKeys = diff.map(x => x.fie_id)
              this.item.files.selected = diff
            }
            this.item.files.selectedItems = this.item.files.selected
          },
        }],
        onSelection: this.onSelection,
      }
    },
    manufacturers: function () {
      return this.$store.getters['manufacturer/filteredItems']('')
    },
    files: function() {
      return this.$store.getters['file/getListFilteredItems'](this.item.files.searchText)
    },
    parameters: function() {
      return this.$store.getters['parameter/currentLanguage']('')
    },
    metaKeywords: function() {
      return this.$store.getters['metaKeyword/currentLanguage']('')
    },
    deliveries: function () {
      return this.$store.getters['delivery/currentLanguage']('')
    },
    language: function () {
      return this.$store.getters['language/active']
    },
    product: function () {
      return this.$store.getters['product/currentLanguage'](this.item.similar.products.searchText)
    },
    payments: function () {
      return this.$store.getters['payment/currentLanguage']('')
    },
    categories: function() {
      return this.$store.getters['category/currentLanguage']
    },
    currentDetailLang: function () {
      return this.$store.getters['product/currentLanguageDetail']
    },
    productIds() {
      return this.$store.getters['product/currentLanguageIds']
    },
    user() {
      return this.$store.getters['auth/getItem']
    },
  },
  methods: {
    fetchGoogleCategory(params) {
      this.loading = true
      this.$store.dispatch('googleCategory/getList', this.$store.getters['pagination/getQuery'](params, 'id', 'asc'))
        .then((response) => {
          const pagination = this.$store.getters['pagination/getDefault']
          pagination.current = response.current_page
          pagination.total = response.total
          this.$store.commit('pagination/SET_PAGINATION', { name: this.tableName, item: pagination })
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    changeSelectedGoogleCategory(id) {
      this.item.exportFeeds.google.categories.selected = id
      if (Array.isArray(id) && id.length !== 0) {
        this.item.exportFeeds.google.categories.selectedObj = this.googleCategories.find(x => x.id === id[0])
      } else {
        this.item.exportFeeds.google.categories.selectedObj = this.googleCategories.find(x => x.id === id)
      }
    },
    isFormInvalid() {
      let formError = false
      if (!this.isOptionTitleError() && !this.isOptionVariantError()) {
        formError = hasErrorsExcept(this.form.getFieldsError(), ['phe_option_title', 'poe_name', 'payments', 'deliveries', 'pdy_fixed_price', 'pdy_always_hide'])
      } else {
        formError = hasErrors(this.form.getFieldsError())
      }
      return this.loading || this.validateOptions() || this.validateCategoryChecked() || formError || (this.mPublicGroups.loaded.includes(this.language) && this.validateGroups())
    },
    isOptionTitleError() {
      return (this.form.getFieldValue('phe_option_title') === undefined || this.form.getFieldValue('phe_option_title').length === 0) && this.form.getFieldValue('poe_name') !== undefined && this.form.getFieldValue('poe_name').length > 0
    },
    isOptionVariantError() {
      return (this.form.getFieldValue('poe_name') === undefined || this.form.getFieldValue('poe_name').length === 0) && this.form.getFieldValue('phe_option_title') !== undefined && this.form.getFieldValue('phe_option_title').length > 0
    },
    setRequiredOptions() {
      this.$nextTick(() => {
        [{ attribute: 'poe_name', data: 'requiredPheOptionTitle' }, {
          attribute: 'phe_option_title',
          data: 'requiredPoeName',
        }].forEach(x => {
          this[x.data] = this.form.getFieldValue(x.attribute) !== undefined && this.form.getFieldValue(x.attribute).length > 0
        })
        this.form.validateFields()
      })
    },
    initActiveReview() {
      this.highlightReview = false
      this.$store.dispatch('review/getOne', this.reviewId)
        .catch(() => {
        })
        .finally(() => {
          this.highlightReview = true
        })
    },
    changeCategory(key) {
      this.item.similar.categories.selected = key
    },
    handleProductSearch(searchText) {
      this.item.similar.products.searchText = searchText
    },
    clickShowModal(user) {
      this.visible = true
      this.visibleId = user.uer_id
      this.visibleUser = user
    },
    banUser(userRegistered, daysBanTo, user) {
      this.loading = true
      this.$store.dispatch('userRegistered/delete', { days: daysBanTo, items: [userRegistered], models: [user] })
        .then(() => {
          this.$store.commit('userRegistered/BAN_DETAIL')
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.visible = false
        })
    },
    loadChildrenReplies(comment) {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/getByParent', comment.cmt_id)
          .then(() => {
            this.$store.commit('product/SET_LOADED_CHILDREN_REPLIES', { item: comment })
            resolve()
          })
          .catch(error => {
            reject(error.errorBag.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    updateComment(comment, message) {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/put', { id: comment.cmt_id, item: { cmt_message: message } })
          .then(() => {
            this.$store.commit('product/UPDATE_COMMENT_MESSAGE', { item: comment, message: message })
            resolve()
          })
          .catch(error => {
            reject(error.errorBag.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    deleteReview(review) {
      this.loading = true
      this.$store.dispatch('review/delete', [review.rvw_id])
        .then(() => {
          this.$store.commit('product/DELETE_REVIEW', review)
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    deleteComment(comment, parent) {
      comment.parent = parent
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/delete', [comment.cmt_id])
          .then(() => {
            this.$store.commit('product/DELETE_COMMENT', comment)
            resolve()
          })
          .catch(error => {
            reject(error.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    replyToComment(comment, message) {
      this.loading = true
      const newComment = {
        cmt_message: message,
        cmt_cmt_id: comment.cmt_id,
        put_id: comment.put_id,
        blg_id: null,
        lge_id: comment.lge_id,
        root_id: comment.root_id === null ? comment.cmt_id : comment.root_id,
        uer_id: this.user.uer_id,
      }
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/post', newComment)
          .then((response) => {
            comment.children_count += 1
            const now = this.$moment()
            newComment.created_at = now
            newComment.updated_at = now
            newComment.user = this.user
            newComment.cmt_id = response.data.cmt_id
            if (comment.children) {
              comment.children.unshift(newComment)
            } else {
              comment.children = [newComment]
            }
            resolve()
          })
          .catch(error => {
            reject(error.errorBag.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    validateCategoryChecked() {
      if (this.item.categories.loaded.length === 0) {
        return false
      }
      return this.item.categories.checkedKeys.length === 0 || (this.item.categories.checkedKeys.checked !== undefined && this.item.categories.checkedKeys.checked.length === 0)
    },
    validateOptions() {
      return (this.form.getFieldValue('phe_option_title') !== undefined && this.form.getFieldValue('phe_option_title').trim().length > 0) && (this.form.getFieldValue('poe_name') === undefined || this.form.getFieldValue('poe_name').length === 0)
    },
    onFileChange(item) {
      this.item.files.selected.splice(this.item.files.selected.indexOf(item), 1)
      this.item.files.selectedRowKeys.splice(this.item.files.selectedRowKeys.indexOf(item.fie_id), 1)
    },
    toTreeData(data) {
      return data.map(x => {
        return {
          key: 'cey' + x.cey_id,
          value: x.cey_id,
          title: '#' + x.cey_id + ' | ' + (x.languages.length === 0 ? 'není nastaveno' : x.languages[0].pivot.cle_name),
          children: this.toTreeData(x.all_children),
        }
      })
    },
    toParamTreeData(data) {
      return data.map(x => {
        return {
          key: 'prr_' + x.prr_id,
          value: 'prr_' + x.prr_id,
          title: this.getParamTitle(x),
          selectable: false,
          children: x.values.map(y => {
            return {
              key: x.prr_id + '_' + y.vue_id,
              value: x.prr_id + '_' + y.vue_id,
              title: (x.languages.length === 0 ? '#' + x.prr_id + ' není nastaveno' : x.languages[0].pivot.ple_name) + ' | ' + (y.languages.length === 0 ? '#' + y.vue_id + ' není nastaveno' : y.languages[0].pivot.vle_value),
              isLeaf: true,
            }
          }),
        }
      })
    },
    changeVat(vat) {
      this.form.setFieldsValue({
        phy_price_vat: parseInt(this.form.getFieldValue('phy_price_no_vat')) * ((parseInt(vat) / 100) + 1),
      })
    },
    calculateVat(value) {
      this.form.setFieldsValue({
        phy_price_vat: value * ((parseInt(this.form.getFieldValue('vat')) / 100) + 1),
      })
    },
    calculateNoVat(value) {
      this.form.setFieldsValue({
        phy_price_no_vat: value / ((parseInt(this.form.getFieldValue('vat')) / 100) + 1),
      })
    },
    onTabFeedChange(key, type) {
      this[type] = key
    },
    onTabChange(key, type) {
      this[type] = key
    },
    handleFileSearch(searchText) {
      this.item.files.searchText = searchText
    },
    error(fieldName) {
      const { getFieldError } = this.form
      return getFieldError(fieldName)
    },
    initDetail() {
      this.loading = true
      this.$store.dispatch('product/getOne', this.id)
        .then(() => {
          if (this.thumbImage.selected.length === 0) {
            this.detail.images.forEach(value => {
              this.thumbImage.selected.push(value)
              if (value.pie_is_main) {
                this.thumbImage.selectedOne = value
              }
            })
          }
          if (this.thumbImage.selected.length !== 0) {
            this.onThumbImageSwitchChange(true)
          }
          this.item.meta_keywords.active.push({
            lge_id: this.language,
            items: this.detail.meta_keywords,
          })

          this.item.manufacturers.active = this.detail.mur_id === null ? undefined : this.detail.mur_id
          this.item.put_ean = this.item.put_ean !== undefined ? this.item.put_ean : this.detail.put_ean
          this.item.put_code = this.item.put_code !== undefined ? this.item.put_code : this.detail.put_code
          this.item.put_warranty = this.item.put_warranty !== undefined ? this.item.put_warranty : this.detail.put_warranty
          this.item.phy_weight = this.item.phy_weight !== undefined ? this.item.phy_weight : this.detail.current_history.phy_weight
          this.item.min_quantity = this.item.min_quantity !== undefined ? this.item.min_quantity : this.detail.min_quantity
          this.item.parameters.active = this.item.parameters.active.length > 0 ? this.item.parameters.active : this.detail.parameters
          this.item.deliveries.active = this.item.deliveries.active.length > 0 ? this.item.deliveries.active : this.detail.deliveries
          this.item.payments.active = this.item.payments.active.length > 0 ? this.item.payments.active : this.detail.payments
          this.item.exportFeeds.google.categories.selected = this.detail.google_feed_category_id === null ? [] : [this.detail.google_feed_category_id]
          if (this.detail.google_feed_category !== null) {
            this.item.exportFeeds.google.categories.switch = true
            this.item.exportFeeds.google.categories.selectedObj = this.detail.google_feed_category
          }

          this.setGroupsDetail(this.discountName)

          if (this.tabActiveKeys.includes('seo')) {
            this.form.setFieldsValue({
              meta_keywords: this.detail.meta_keywords,
            })
          }

          if (this.tabActiveKeys.includes('parameters')) {
            this.form.setFieldsValue({
              phy_weight: this.item.phy_weight,
              mur_id: this.detail.mur_id === null ? undefined : this.detail.mur_id,
              put_ean: this.detail.put_ean,
              min_quantity: this.detail.min_quantity,
              put_code: this.detail.put_code,
              put_warranty: this.detail.put_warranty,
              parameters: this.detail.parameters,
            })
          }

          if (this.tabActiveKeys.includes('stickers')) {
            this.form.setFieldsValue({
              stickers: this.detail.stickers.map(x => x.ser_id),
            })
          }

          this.item.similar.selected = this.detail.similar_first.map(x => {
            x.product_second.selected = true
            return x.product_second
          })
          this.item.similar.products.selectedItems = this.item.similar.selected
          this.item.similar.products.selectedRowKeys = this.detail.similar_first.map(x => x.put_id_second)

          if (this.tabActiveKeys.includes('deliveries')) {
            this.deliveries.forEach(value => {
              this.item.deliveries.pdy_always_hide[value.dly_id] = undefined
            })
            this.detail.deliveries.forEach(value => {
              this.item.deliveries.pdy_always_hide[value.dly_id] = value.pdy_always_hide
              this.item.deliveries.pdy_fixed_price[value.dly_id] = value.pdy_fixed_price
            })
          }

          if (this.tabActiveKeys.includes('feeds')) {
            this.detail.feeds.forEach(value => {
              this.form.getFieldDecorator('identifier[' + value.feed_id + ']')
              this.form.getFieldDecorator('sync[' + value.feed_id + ']')
              this.form.setFieldsValue({
                ['identifier[' + value.feed_id + ']']: value.identifier,
                ['sync[' + value.feed_id + ']']: value.sync,
              })
            })
          }

          if (this.tabActiveKeys.includes('payments')) {
            this.payments.forEach(value => {
              this.item.payments.payments[value.pmt_id] = true
            })
            this.detail.payments.forEach(value => {
              this.item.payments.payments[value.pmt_id] = false
            })
          }

          this.detail.files.forEach(value => {
            if (!this.item.files.selectedRowKeys.includes(value.fie_id)) {
              this.item.files.selectedRowKeys.push(value.fie_id)
              value.selected = true
              this.item.files.selected.push(value)
            }
          })
          this.item.files.selectedItems = this.item.files.selected

          this.detail.categories.forEach(value => {
            if (!this.item.categories.checkedKeys.checked.includes('cey' + value.cey_id)) {
              this.item.categories.checkedKeys.checked.push('cey' + value.cey_id)
            }
            if (value.pce_is_main && !this.item.categories.selectedKeys.includes('cey' + value.cey_id)) {
              this.item.categories.selectedKeys.push('cey' + value.cey_id)
            }
          })

          if (this.tabActiveKeys.includes('main')) {
            this.initMain()
          }
          this.editorContent = this.currentDetailLang.languages.ple_description_long
        })
        .catch(() => {})
        .finally(() => {
          this.loaded.push(this.language)
          this.loading = false
        })
    },
    setThenFields(fields) {
      fields.forEach(field => {
        this.item[field.itemName][field.fieldName].forEach((value) => {
          this.form.getFieldDecorator(field.fieldName + '[' + value.key + ']')
          this.form.setFieldsValue({
            [field.fieldName + '[' + value.key + ']']: value.value,
          })
        })
      })
    },
    loadDependency(items, itemNamesList, dependencies, thenFields = []) {
      items.forEach((value) => {
        value.loading = true
      })
      Promise.all(dependencies)
        .then(() => {
          this.setThenFields(thenFields)

          if (itemNamesList.includes('parameters')) {
            this.form.setFieldsValue({
              mur_id: this.detail.mur_id === null ? undefined : this.detail.mur_id,
              put_ean: this.detail.put_ean,
              put_code: this.detail.put_code,
              put_warranty: this.detail.put_warranty,
              min_quantity: this.detail.min_quantity,
              phy_weight: this.detail.current_history.phy_weight,
              parameters: this.detail.parameters,
            })
          }

          if (itemNamesList.includes('stickers')) {
            this.form.setFieldsValue({
              stickers: this.detail.stickers.map(x => x.ser_id),
            })
          }

          if (itemNamesList.includes('deliveries')) {
            this.detail.deliveries.forEach(value => {
              this.item.deliveries.pdy_always_hide[value.dly_id] = value.pdy_always_hide
              this.item.deliveries.pdy_fixed_price[value.dly_id] = value.pdy_fixed_price
            })
          }

          if (itemNamesList.includes('payments')) {
            this.payments.forEach(value => {
              this.item.payments.payments[value.pmt_id] = true
            })
            this.detail.payments.forEach(value => {
              this.item.payments.payments[value.pmt_id] = false
            })
          }

          if (itemNamesList.includes('meta_keywords')) {
            this.form.setFieldsValue({
              meta_keywords: this.detail.meta_keywords,
            })
          }
        })
        .catch(() => {})
        .finally(() => {
          items.forEach((value) => {
            value.loading = false
            value.loaded.push(this.language)
          })
        })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('product/put', { id: this.id, item: this.getData() })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    setCategories(obj) {
      const categories = []
      if (this.item.categories.selectedKeys.length > 0) {
        categories.push({
          cey_id: Number.parseInt(this.item.categories.selectedKeys[0].slice(3)),
          pce_is_main: true,
        })
      }
      this.item.categories.checkedKeys.checked.filter(x => !this.item.categories.selectedKeys.includes(x)).forEach(value => {
        categories.push({
          cey_id: Number.parseInt(value.slice(3)),
          pce_is_main: false,
        })
      })
      obj.categories = categories
      return obj
    },
    setFeeds(obj) {
      const feeds = []
      this.feeds.forEach(x => {
        if (this.form.getFieldValue('identifier[' + x.id + ']') !== undefined && this.form.getFieldValue('identifier[' + x.id + ']').trim().length !== 0) {
          feeds.push({
            feed_id: x.id,
            sync: this.form.getFieldValue('sync[' + x.id + ']') === undefined ? false : this.form.getFieldValue('sync[' + x.id + ']'),
            identifier: this.form.getFieldValue('identifier[' + x.id + ']'),
          })
        }
      })
      obj.feeds = feeds
      return obj
    },
    loadFeeds() {
      this.loading = true
      this.item.feeds.loading = true
      this.$store.dispatch('feeds/getList')
        .then(() => {
          this.item.feeds.loaded = true
          this.detail.feeds.forEach(value => {
            this.form.getFieldDecorator('identifier[' + value.feed_id + ']')
            this.form.getFieldDecorator('sync[' + value.feed_id + ']')
            this.form.setFieldsValue({
              ['identifier[' + value.feed_id + ']']: value.identifier,
              ['sync[' + value.feed_id + ']']: value.sync,
            })
          })
        }).catch(() => {
        })
        .finally(() => {
          this.loading = false
          this.item.feeds.loading = false
        })
    },
    setParameters(obj) {
      let parameters = []
      if (this.item.parameters.loaded.length === 0) {
        parameters = this.item.parameters.active
      } else if (this.form.getFieldValue('parameters') !== undefined) {
        this.form.getFieldValue('parameters').forEach(value => {
          let found = false
          const prrId = parseInt(value.substr(0, value.indexOf('_')))
          const vueId = parseInt(value.substr(value.indexOf('_') + 1))
          parameters.some((x, key) => {
            if (x.prr_id === prrId) {
              found = true
              parameters[key].values.push(vueId)
              return true
            }
          })
          if (!found) {
            parameters.push({
              prr_id: prrId,
              values: [vueId],
            })
          }
        })
      }

      obj.parameters = {
        mur_id: this.form.getFieldValue('mur_id') === undefined ? null : parseInt(this.form.getFieldValue('mur_id')),
        min_quantity: parseInt(this.form.getFieldValue('min_quantity')),
        put_ean: this.form.getFieldValue('put_ean') === undefined || this.form.getFieldValue('put_ean') === null || this.form.getFieldValue('put_ean').trim().length === 0 ? null : this.form.getFieldValue('put_ean'),
        put_code: this.form.getFieldValue('put_code') === undefined || this.form.getFieldValue('put_code') === null || this.form.getFieldValue('put_code').trim().length === 0 ? null : this.form.getFieldValue('put_code'),
        put_warranty: this.form.getFieldValue('put_warranty') === undefined ? null : this.form.getFieldValue('put_warranty'),
        parameters: parameters,
        phy_weight: this.form.getFieldValue('phy_weight') === undefined ? null : this.form.getFieldValue('phy_weight'),
      }
      return obj
    },
    setDeliveries(obj) {
      let deliveries = []
      if (this.item.deliveries.loaded.length === 0) {
        deliveries = this.item.deliveries.active
      } else {
        this.deliveries.forEach(item => {
          if ((this.item.deliveries.pdy_always_hide[item.dly_id] !== undefined && this.item.deliveries.pdy_always_hide[item.dly_id] !== false) || (this.item.deliveries.pdy_fixed_price[item.dly_id] !== undefined && this.item.deliveries.pdy_fixed_price[item.dly_id] !== null)) {
            deliveries.push({
              dly_id: item.dly_id,
              pdy_always_hide: this.item.deliveries.pdy_always_hide[item.dly_id] === true,
              pdy_fixed_price: this.item.deliveries.pdy_fixed_price[item.dly_id] !== undefined && this.item.deliveries.pdy_fixed_price[item.dly_id] >= 0 ? this.item.deliveries.pdy_fixed_price[item.dly_id] : null,
            })
          }
        })
      }
      obj.deliveries = deliveries
      return obj
    },
    setImages(obj) {
      const images = []
      this.thumbImage.selected.forEach((value, key) => {
        images.push({
          iae_id: value.iae_id,
          pie_is_main: this.thumbImage.selectedOne !== null && this.thumbImage.selectedOne.iae_id === value.iae_id,
        })
      })
      obj.images = images
      return obj
    },
    setPayments(obj) {
      let payments = []
      if (this.item.payments.loaded.length === 0) {
        payments = this.item.payments.active.map(x => x.pmt_id)
      } else if (this.item.payments.payments !== undefined) {
        this.item.payments.payments.forEach((value, key) => {
          if (this.item.payments.payments[key] === false) {
            payments.push(key)
          }
        })
      }
      obj.payments = payments
      return obj
    },
    setSimilar(obj) {
      obj.similar = this.item.similar && this.item.similar.selected && Array.isArray(this.item.similar.selected) ? this.item.similar.selected.map(x => x.put_id) : []
      return obj
    },
    setSeo(obj) {
      const metaKeywords = {
        new: {
          count: 0,
          languages: [],
        },
        existing: [],
      }
      if (this.item.meta_keywords.loaded.length === 0) {
        metaKeywords.existing = this.item.meta_keywords.active.length > 0 ? this.item.meta_keywords.active[0].items.map(x => parseInt(x)) : []
      } else if (this.form.getFieldValue('meta_keywords') !== undefined) {
        metaKeywords.new = {
          count: this.item.meta_keywords.active[0].items.length,
          languages: this.item.meta_keywords.active[0].items.length === 0 ? [] : this.item.meta_keywords.active,
        }
        this.form.getFieldValue('meta_keywords').forEach(x => {
          if (!typeOfNaN(x) && this.metaKeywords.find(y => y.mkd_id === parseInt(x))) {
            metaKeywords.existing.push(parseInt(x))
          }
        })
      }
      obj.meta_keywords = metaKeywords
      return obj
    },
    setFiles(obj) {
      obj.files = this.item.files.selectedRowKeys
      return obj
    },
    setStickers(obj) {
      let stickers = []
      if (this.item.stickers.loaded.length === 0) {
        stickers = this.detail.stickers.map(x => x.ser_id)
      } else {
        stickers = this.form.getFieldValue('stickers') === undefined || this.form.getFieldValue('stickers') === null ? [] : this.form.getFieldValue('stickers')
      }
      obj.stickers = stickers
      return obj
    },
    setMainInformation(obj) {
      const options = []
      this.item.options.forEach(value => {
        const filteredItems = value.poe_name !== undefined ? value.poe_name.filter(x => x !== undefined && x.trim().length > 0) : []
        if (filteredItems.length > 0) {
          options.push({
            lge_id: value.lge_id,
            items: filteredItems,
          })
        }
      })
      let googleFeedCategoryId = null
      if (this.item.exportFeeds.google.categories.switch &&
        Array.isArray(this.item.exportFeeds.google.categories.selected) &&
        this.item.exportFeeds.google.categories.selected.length !== 0) {
        googleFeedCategoryId = this.item.exportFeeds.google.categories.selected[0]
      }
      obj = {
        google_feed_category_id: googleFeedCategoryId,
        put_availability: parseInt(this.form.getFieldValue('put_availability')),
        put_is_active: this.form.getFieldValue('put_is_active') === true,
        sellable: this.form.getFieldValue('sellable') === true,
        languages: this.item.languages
          .map(x => {
            return {
              lge_id: x.lge_id,
              ple_description_long: x.ple_description_long && x.ple_description_long.trim().length > 0 ? x.ple_description_long : null,
              ple_description_short: x.ple_description_short && x.ple_description_short.trim().length > 0 ? x.ple_description_short : null,
            }
          }),
        options: options,
        history: {
          phy_price_vat: this.form.getFieldValue('phy_price_vat'),
          phy_price_no_vat: this.form.getFieldValue('phy_price_no_vat'),
          vat: parseInt(this.form.getFieldValue('vat')),
        },
        history_languages: this.item.history_languages.filter(x => x.phe_name && x.phe_name.trim().length > 0 && x.phe_url && x.phe_url.trim().length > 0).map(x => {
          return {
            lge_id: x.lge_id,
            phe_name: x.phe_name,
            phe_url: x.phe_url,
            phe_option_title: x.phe_option_title && x.phe_option_title.trim().length > 0 ? x.phe_option_title : null,
          }
        }),
      }
      return obj
    },
    getData() {
      let returnObj = {}
      returnObj = this.setMainInformation(returnObj)
      if (this.visitedTabs.has('categories')) {
        returnObj = this.setCategories(returnObj)
      }
      if (this.visitedTabs.has('feeds') && this.item.feeds.loaded) {
        returnObj = this.setFeeds(returnObj)
      }
      if (this.visitedTabs.has('parameters')) {
        returnObj = this.setParameters(returnObj)
      }
      if (this.visitedTabs.has('groups')) {
        returnObj = this.setGroups(returnObj, 'group_discounts', this.discountName)
      }
      if (this.visitedTabs.has('deliveries')) {
        returnObj = this.setDeliveries(returnObj)
      }
      if (this.visitedTabs.has('images')) {
        returnObj = this.setImages(returnObj)
      }
      if (this.visitedTabs.has('payments')) {
        returnObj = this.setPayments(returnObj)
      }
      if (this.visitedTabs.has('similar')) {
        returnObj = this.setSimilar(returnObj)
      }
      if (this.visitedTabs.has('seo')) {
        returnObj = this.setSeo(returnObj)
      }
      if (this.visitedTabs.has('files')) {
        returnObj = this.setFiles(returnObj)
      }
      if (this.visitedTabs.has('stickers')) {
        returnObj = this.setStickers(returnObj)
      }
      return returnObj
    },
    onCategoryCheck(checkedKeys) {
      this.item.categories.checkedKeys = checkedKeys
    },
    onCategorySelect(selectedKeys, info) {
      this.item.categories.selectedKeys = selectedKeys
    },
   /* changeItems(formItems, itemName, storeItem, id, oldLang) {
      formItems.forEach(formItem => {
        this.$store.getters[storeItem + '/byLanguage']('', oldLang).forEach((value, key) => {
          if (value !== undefined && this.form.getFieldValue(formItem + '[' + value[id] + ']') !== undefined) {
            let found = false
            this.item[itemName][formItem].some(itemItem => {
              if (itemItem.key === value[id]) {
                itemItem.value = this.form.getFieldValue(formItem + '[' + value[id] + ']')
                found = true
                return true
              }
            })
            if (!found) {
              this.item[itemName][formItem].push({
                key: value[id],
                value: this.form.getFieldValue(formItem + '[' + value[id] + ']'),
              })
            }
          }
        })
      }), */
    handleChange(oldLanguage) {
      const foundObj = { history_languages: false, meta: false, deliveries: false, payments: false, languages: false, options: false }
      const newObj = {
        history_languages: {
          lge_id: oldLanguage,
          phe_name: this.form.getFieldValue('phe_name'),
          phe_url: this.form.getFieldValue('phe_url'),
          phe_option_title: this.form.getFieldValue('phe_option_title'),
        },
        languages: {
          lge_id: oldLanguage,
          ple_description_short: this.form.getFieldValue('ple_description_short'),
          ple_description_long: this.form.getFieldValue('ple_description_long'),
        },
        options: {
          lge_id: oldLanguage,
          poe_name: this.form.getFieldValue('poe_name'),
        },
        meta: {
          lge_id: oldLanguage,
        },
      }

      // this.changeItems(['pdy_always_hide', 'pdy_fixed_price'], 'deliveries', 'delivery', 'dly_id', oldLanguage)
      // this.changeItems(['payments'], 'payments', 'payment', 'pmt_id', oldLanguage)

      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.languages
          foundObj.languages = true
          return true
        }
      })

      if (this.tabActiveKeys.includes('seo')) {
        newObj.meta.items = this.form.getFieldValue('meta_keywords') === undefined ? [] : this.form.getFieldValue('meta_keywords').filter(x => { return !(!typeOfNaN(x) && this.metaKeywords.find(y => y.mkd_id === parseInt(x))) })

        this.item.meta_keywords.active.some((value, key) => {
          if (value.lge_id === oldLanguage) {
            this.item.meta_keywords.active[key] = newObj.meta
            foundObj.meta = true
            return true
          }
        })
        if (!foundObj.meta) {
          this.item.meta_keywords.active.push(newObj.meta)
        }
      }
      this.item.history_languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.history_languages[key] = newObj.history_languages
          foundObj.history_languages = true
          return true
        }
      })
      this.item.options.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.options[key] = newObj.options
          foundObj.options = true
          return true
        }
      })
      if (!foundObj.languages) {
        this.item.languages.push(newObj.languages)
      }
      if (!foundObj.history_languages) {
        this.item.history_languages.push(newObj.history_languages)
      }
      if (!foundObj.options) {
        this.item.options.push(newObj.options)
      }
    },
    loadParameters() {
      this.loadDependency([
        this.item.parameters,
        this.item.manufacturers,
      ], [
        'parameters',
        'manufacturers',
      ], [
        this.$store.dispatch('parameter/getList', 'values.languages'),
        this.$store.dispatch('manufacturer/getList'),
      ])
    },
    loadOthers(value, nameList, thenFields = []) {
      this.loadDependency([
        this.item[value],
      ], nameList, [
        this.$store.dispatch(this.item[value].dispatch),
      ], thenFields)
    },
    loadMetaKeywords() {
      this.loadDependency([
        this.item.meta_keywords,
      ], [
        'meta_keywords',
      ], [
        this.$store.dispatch('metaKeyword/getList'),
      ])
    },
    loadProducts() {
      if (this.language !== null) {
        this.loadDependency([
          this.item.similar.products,
        ], ['similar'], [
          this.$store.dispatch('product/getList', this.item.similar.categories.selected),
        ])
      }
    },
    onProductSimilarChange(item) {
      this.item.similar.selected.splice(this.item.similar.selected.indexOf(item), 1)
      this.item.similar.products.selectedRowKeys.splice(this.item.similar.products.selectedRowKeys.indexOf(item.put_id), 1)
    },
    isUserBanned(node) {
      const banned = node.user.banned_to && node.user.uer_type !== 'AMR'
      let found = false
      node.user.ip_addresses.some((value) => {
        if (this.$moment().diff(value.banned_to) <= 0) {
          found = true
          return true
        }
      })
      return banned || found
    },
    getParamTitle(x) {
      const title = '#' + x.prr_id
      if (!x.languages || x.languages.length === 0) {
        return title + ' | název není nastaven'
      }
      if (x.languages[0].pivot.ple_unit !== null) {
        return title + ' | ' + x.languages[0].pivot.ple_name + ' [' + x.languages[0].pivot.ple_unit + ']'
      }
      return title + ' | ' + x.languages[0].pivot.ple_name
    },
    onFileSelectChange(selectedRowKeys, items) {
      this.item.files.selectedRowKeys = selectedRowKeys
      this.item.files.selectedItems = items
      if (this.item.files.selectedRowKeys.length > this.item.files.selected.length) {
        this.item.files.selectedItems.some(value => {
          let found = false
          this.item.files.selected.some(sim => {
            if (sim.fie_id === value.fie_id) {
              found = true
              return true
            }
          })
          if (!found) {
            value.selected = true
            this.item.files.selected.push(value)
          }
        })
      } else {
        this.item.files.selected = [...new Set(this.item.files.selected.filter(x => this.item.files.selectedRowKeys.includes(x.fie_id)))]
      }
    },
    onProductSelectChange(selectedRowKeys, items) {
      this.item.similar.products.selectedRowKeys = selectedRowKeys
      this.item.similar.products.selectedItems = items
      if (this.item.similar.products.selectedRowKeys.length > this.item.similar.selected.length) {
        this.item.similar.products.selectedItems.some(value => {
          let found = false
          this.item.similar.selected.some(sim => {
            if (sim.put_id === value.put_id) {
              found = true
              return true
            }
          })
          if (!found) {
            value.selected = true
            this.item.similar.selected.push(value)
          }
        })
      } else {
        this.item.similar.selected = [...new Set(this.item.similar.selected.filter(x => this.item.similar.products.selectedRowKeys.includes(x.put_id))
          .concat(this.item.similar.selected.filter(x => !this.productIds.includes(x.put_id))))]
      }
    },
    refreshComponent(id) {
      this.id = id
      this.visible = null
      this.visibleId = null
      this.visibleUser = null
      this.editorContent = ''
      this.loading = false
      this.refreshThumbImage()
      this.item.stickers.loading = false
      this.item.stickers.loaded = []
      this.item.similar.categories.selected = undefined
      this.item.similar.selected = []
      this.item.similar.products.selectedItems = []
      this.item.similar.categories.products = {
        selectedRowKeys: [],
        selectedItems: [],
        searchText: '',
        loaded: [],
        loading: false,
      }
      this.item.languages = []
      this.item.history = {}
      this.item.history_languages = []
      this.item.options = []
      this.item.categories.loading = false
      this.item.categories.checkedKeys.checked = []
      this.item.categories.selectedKeys = []
      this.item.manufacturers.loading = false
      this.item.manufacturers.loaded = []
      this.item.manufacturers.active = null
      this.item.files.loading = false
      this.item.files.selected = []
      this.item.files.selectedItems = []
      this.item.files.loaded = []
      this.item.files.searchText = ''
      this.item.files.selectedRowKeys = []
      this.item.parameters.loading = false
      this.item.parameters.loaded = []
      this.item.parameters.treeData = []
      this.item.parameters.active = []
      this.refreshGroups()
      this.item.payments.loading = false
      this.item.payments.payments = []
      this.item.payments.active = []
      this.item.payments.loaded = []
      this.item.meta_keywords = {
        loaded: [],
        lang: true,
        loading: false,
        active: [],
      }
      this.item.deliveries.active = []
      this.item.deliveries.pdy_fixed_price = []
      this.item.deliveries.pdy_always_hide = []
      this.item.deliveries.loaded = []
      this.loading = false
      this.loaded = []
    },
    initTabActiveKey() {
      if (this.$route.params.commentId) {
        return 'comments'
      }
      if (this.$route.params.reviewId) {
        return 'reviews'
      }
      return 'main'
    },
    initMain() {
      this.form.getFieldDecorator('put_availability')
      this.form.getFieldDecorator('vat')
      this.form.getFieldDecorator('put_is_active')
      this.form.getFieldDecorator('sellable')
      this.form.getFieldDecorator('ple_description_short')
      this.form.getFieldDecorator('ple_description_long')
      this.form.getFieldDecorator('poe_name')
      this.form.getFieldDecorator('phy_price_vat')
      this.form.getFieldDecorator('phy_price_no_vat')
      this.form.getFieldDecorator('phe_name')
      this.form.getFieldDecorator('phe_url')
      this.form.getFieldDecorator('phe_option_title')

      this.form.setFieldsValue({
        put_availability: this.detail.put_availability,
        vat: this.detail.current_history.vat,
        put_is_active: this.detail.put_is_active,
        sellable: this.detail.sellable,
        ple_description_short: this.currentDetailLang.languages.ple_description_short,
        ple_description_long: this.currentDetailLang.languages.ple_description_long,
        poe_name: this.currentDetailLang.current_options.poe_name.filter(x => x !== undefined),
        phy_price_vat: this.detail.current_history.phy_price_vat,
        phy_price_no_vat: this.detail.current_history.phy_price_no_vat,
        phe_name: this.currentDetailLang.history_languages.phe_name,
        phe_url: this.currentDetailLang.history_languages.phe_url,
        phe_option_title: this.currentDetailLang.history_languages.phe_option_title === null ? undefined : this.currentDetailLang.history_languages.phe_option_title,
      })
    },
  },
  created() {
    this.inputCategoryUrl.inputUrlName = 'phe_url'
    this.inputCategoryUrl.exclude = undefined
    if (this.categories.length !== 0) {
      this.item.categories.treeData = this.toTreeData(this.categories)
      this.item.similar.categories.treeData = this.toTreeData(this.categories)
      this.form.validateFields()
    }
    if (this.parameters.length !== 0) {
      this.item.parameters.treeData = this.toParamTreeData(this.parameters)
    }
    if (this.language !== null) {
      this.initDetail()
    }
    if (this.reviewId) {
      this.initActiveReview()
    }
  },
  updated() {
    if (this.activeReview && this.reviewId) {
      this.$nextTick(() => {
        this.$refs.activeReview.$el.scrollIntoView()
      })
    }
  },
  watch: {
    'item.files.selectedItems'(newValue, oldValue) {
      if (this.item.files.selectedRowKeys.length > this.item.files.selected.length) {
        this.item.files.selectedItems.some(value => {
          let found = false
          this.item.files.selected.some(sim => {
            if (sim.fie_id === value.fie_id) {
              found = true
              return true
            }
          })
          if (!found) {
            value.selected = true
            this.item.files.selected.push(value)
          }
        })
      } else {
        this.item.files.selected = this.item.files.selected.filter(x => this.item.files.selectedRowKeys.includes(x.fie_id))
      }
    },
    '$route.params.commentId'(newId, oldId) {
      if (newId) {
        this.commentId = newId
        this.tabActiveKey = 'comments'
      }
    },
    '$route.params.reviewId'(newId, oldId) {
      if (newId) {
        this.reviewId = newId
        this.tabActiveKey = 'reviews'
        this.initActiveReview()
      }
    },
    'item.similar.categories.selected'(newValue, oldValue) {
      if (newValue !== undefined && newValue !== null) {
        this.loadProducts()
      }
    },
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      if (this.language !== null) {
        this.initDetail()
      }
    },
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        if (this.tabActiveKey === 'seo' && this.item.meta_keywords.loaded.length !== 0 && !this.item.meta_keywords.loaded.includes(this.language)) {
          this.loadMetaKeywords()
        }
        if (this.tabActiveKey === 'parameters' && this.item.parameters.loaded.length !== 0 && !this.item.parameters.loaded.includes(this.language)) {
          this.loadParameters()
        }
        if (this.tabActiveKey === 'deliveries' || this.tabActiveKey === 'payments' || this.tabActiveKey === 'categories') {
          if (this.item[this.tabActiveKey].loaded.length !== 0 && !this.item[this.tabActiveKey].loaded.includes(this.language)) {
            this.loadOthers(this.tabActiveKey, [this.tabActiveKey], this.item[this.tabActiveKey].fields)
          }
        }
        if (!this.loaded.includes(this.language)) {
          this.initDetail()
        } else {
          const foundObj = {
            history_languages: this.item.history_languages.find(x => x.lge_id === newValue),
            languages: this.item.languages.find(x => x.lge_id === newValue),
            options: this.item.options.find(x => x.lge_id === newValue),
            meta: this.item.meta_keywords.active.find(x => x.lge_id === newValue),
          }
          const newObj = {
            phe_name: foundObj.history_languages === undefined ? undefined : foundObj.history_languages.phe_name,
            phe_url: foundObj.history_languages === undefined ? undefined : foundObj.history_languages.phe_url,
            phe_option_title: foundObj.history_languages === undefined ? undefined : foundObj.history_languages.phe_option_title,
            ple_description_short: foundObj.languages === undefined ? undefined : foundObj.languages.ple_description_short,
            ple_description_long: foundObj.languages === undefined ? undefined : foundObj.languages.ple_description_long,
            poe_name: foundObj.options === undefined ? undefined : foundObj.options.poe_name,
          }

          this.editorContent = newObj.ple_description_long === undefined ? '' : newObj.ple_description_long
          newObj.meta_keywords = this.form.getFieldValue('meta_keywords') === undefined ? [] : this.form.getFieldValue('meta_keywords').filter(x => { return !typeOfNaN(x) && this.metaKeywords.find(y => y.mkd_id === parseInt(x)) })
          if (foundObj.meta === undefined) {
            if (oldValue !== null) {
              const lastLangMetaKeywords = this.item.meta_keywords.active.find(x => x.lge_id === oldValue).items
              if (lastLangMetaKeywords !== undefined) {
                newObj.meta_keywords = newObj.meta_keywords.concat(lastLangMetaKeywords)
              }
            }
          } else {
            newObj.meta_keywords = newObj.meta_keywords.concat(foundObj.meta.items)
          }
          newObj.meta_keywords = newObj.meta_keywords.filter((item, index) => newObj.meta_keywords.indexOf(item) === index)
          this.form.setFieldsValue(newObj)
        }
        this.form.validateFields()
      }
    },
    editorContent(newValue) {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          ple_description_long: newValue,
        })
      })
    },
    categories(newValue, oldValue) {
      if (newValue !== null && newValue.length > 0) {
        this.item.categories.treeData = this.toTreeData(this.categories)
        this.item.similar.categories.treeData = this.toTreeData(this.categories)
        this.form.validateFields()
      }
    },
    parameters(newValue, oldValue) {
      if (newValue !== null && newValue.length > 0) {
        this.item.parameters.treeData = this.toParamTreeData(this.parameters)
      }
    },
    tabActiveKey(newValue, oldValue) {
      this.visitedTabs.add(newValue)
      if (!this.tabActiveKeys.includes(newValue)) {
        if (newValue === 'main') {
          this.initMain()
        }
        this.tabActiveKeys.push(newValue)
      }
      if (['main', 'comments', 'reviews', 'images'].includes(newValue)) {
        return false
      }
      if (newValue === 'feeds') {
        if (!this.item.feeds.loaded) {
          this.loadFeeds()
        }
      } else if (newValue === 'similar') {
        if (!this.item.categories.loaded.includes(this.language)) {
          this.loadOthers('categories', ['categories'], this.item.categories.fields)
        }
      } else if (newValue === 'seo') {
        if (!this.item.meta_keywords.loaded.includes(this.language)) {
          this.loadMetaKeywords()
        }
      } else if (newValue === 'groups') {
        if (!this.mPublicGroups.loaded.includes(this.language)) {
          this.loadPublicGroups(this.discountName, true)
        }
      } else if (newValue === 'parameters') {
        if (!this.item.parameters.loaded.includes(this.language)) {
          this.loadParameters()
        }
      } else if (this.item[newValue].loaded.length === 0 || (!this.item[newValue].loaded.includes(this.language) && this.item[newValue].lang)) {
        if (newValue === 'deliveries' || newValue === 'payments') {
          this.loadOthers(newValue, [newValue], this.item[newValue].fields)
        } else {
          this.loadOthers(newValue, [newValue])
        }
      }
      /* if (newValue === 'deliveries' || newValue === 'payments') {
        this.setThenFields(this.item[newValue].fields)
      } */
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
