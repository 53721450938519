<template>
  <div>
    <a-list class="list-tree" itemLayout="vertical" size="large" :pagination="false" v-if="activeCommentId && activeComment" :dataSource="[activeComment]">
      <list-tree-node
        :highlighted="highlighted"
        slot="renderItem"
        slot-scope="item"
        :node="item"
        :key="item.cmt_id"
        :delete-comment="deleteComment"
        :is-user-banned="isUserBanned"
        :click-show-modal="clickShowModal"
        :reply-to-comment="replyToComment"
        :active-user-id="activeUserId"
        :loading="loading"
        :load-children-replies="loadChildrenReplies"
        :update-comment="updateComment"
        v-if="!item.deleted"
      ></list-tree-node>
    </a-list>
    <a-list class="list-tree" itemLayout="vertical" size="large" :pagination="treeData.length > pagination.pageSize ? pagination : false" :dataSource="treeData">
      <list-tree-node
        :highlighted="false"
        slot="renderItem"
        slot-scope="item"
        :node="item"
        :key="item.cmt_id"
        :parent="item"
        :quote-parent="quoteParent"
        :delete-comment="deleteComment"
        :is-user-banned="isUserBanned"
        :click-show-modal="clickShowModal"
        :reply-to-comment="replyToComment"
        :active-user-id="activeUserId"
        :loading="loading"
        :load-children-replies="loadChildrenReplies"
        :update-comment="updateComment"
        v-if="!item.deleted"
      ></list-tree-node>
    </a-list>
  </div>
</template>

<script>
import ListTreeNode from './ListTreeNode'

export default {
  name: 'ListTree',
  props: {
    quoteParent: Boolean,
    treeData: Array,
    pagination: Object,
    isUserBanned: Function,
    activeUserId: Number,
    deleteComment: Function,
    clickShowModal: Function,
    loading: Boolean,
    replyToComment: Function,
    loadChildrenReplies: Function,
    updateComment: Function,
    activeCommentId: Number,
  },
  components: {
    ListTreeNode,
  },
  data() {
    return {
      highlighted: true,
    }
  },
  computed: {
    activeComment: function () {
      return this.$store.getters['comment/getDetail']
    },
  },
  methods: {
    initActiveComment() {
      this.$store.dispatch('comment/getOne', this.activeCommentId)
        .catch(() => {
        })
    },
  },
  created() {
    if (this.activeCommentId) {
      this.initActiveComment()
    }
  },
  watch: {
    activeCommentId(newValue) {
      this.initActiveComment()
      this.highlighted = false
      this.$nextTick(() => {
        this.highlighted = true
      })
    },
  },
}
</script>

<style scoped>

</style>
