<template>
  <a-table
    :rowSelection="rowSelection"
    tableLayout="auto"
    @change="handleTableChange"
    :rowKey="record => record[idName]"
    :columns="columns"
    :loading="loading"
    :dataSource="dataSource"
    :pagination="pagination"
    class="utils__scrollTable"
    :class="{'disabled': disabled}"
    :scroll="{ x: '100%' }"
  >
    <div
      slot="filterDropdown"
      slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
      style="padding: 8px"
    >
      <a-input
        :class="{'smaller-placeholder': column.pattern}"
        v-ant-ref="c => (searchInput = c)"
        :placeholder="`${(column.pattern ? column.pattern : 'Vyhledat ' + column.title)}`"
        :value="selectedKeys[0]"
        style="width: 188px; margin-bottom: 8px; display: block;"
        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
        @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
      />
      <a-button
        type="primary"
        icon="search"
        size="small"
        style="width: 90px; margin-right: 8px"
        @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
      >
        Vyhledat
      </a-button>
      <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
        Smazat
      </a-button>
    </div>
    <a-icon
      slot="filterIcon"
      slot-scope="filtered"
      type="search"
      :style="{ color: filtered ? '#108ee9' : undefined }"
    />
    <router-link
      slot="odr_id"
      slot-scope="value"
      class="utils__link--underlined"
      :to="detailUrl + value"
    >#{{ value }}
    </router-link>
    <template slot="customRender" slot-scope="text">
      <div :key="'muk'+ind" v-for="(item, ind) in text.toString().match(/.{1,25}(\s|$)/g)">{{ item }}</div>
    </template>
    <template slot="price" slot-scope="text">
      {{ text === null ? 0 : text | currency }}
    </template>
    <span
      slot="active"
      slot-scope="value"
      :class="[value === undefined ? '' : value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
    >
        {{ value ? 'ano' : 'ne' }}
      </span>
    <span
      slot="deleted"
      slot-scope="value"
      :class="[value === undefined ? '' : value ? 'font-size-12 badge badge-danger' : 'font-size-12 badge badge-success']"
    >
        {{ value ? 'ano' : 'ne' }}
      </span>
    <span
      slot="dates"
      slot-scope="text"
    >
        {{ toLocaleDate(text) }}
      </span>
    <span slot="action" slot-scope="record">
          <router-link :to="detailUrl+record.odr_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record[idName]])" okText="Potvrdit" cancelText="Zrušit"
                        v-if="!record.is_deleted">
            <template slot="title">
              <p>Opravdu si přejete odstranit položku: #{{ record[idName] }} ?</p>
            </template>
            <a-button icon="delete" size="small">Odstranit</a-button>
          </a-popconfirm>
        </span>
    <span slot="telephoneRender" slot-scope="text, record">
      {{ getTelephoneNumberFormatted('(' + record.phone_code + ')' + record.telephone_number) }}
    </span>
    <span slot="note" slot-scope="text">
          <a-button v-if="text !== null" @click="showNote(text)">Zobrazit</a-button>
          <a-alert type="info" message="Není" v-else :showIcon="true"></a-alert>
        </span>
    <span slot="status" slot-scope="text">
      <a-tag :color="statuses.find(x => x.status === text).color">
        {{ statuses.find(x => x.status === text).text }}
      </a-tag>
    </span>
    <template slot="trackStatus" slot-scope="text">
      <a-badge v-if="text !== null" :color="trackStatuses.find(x => x.status === text).color"
               :text="trackStatuses.find(x => x.status === text).text"></a-badge>
      <a-badge status="default" v-else></a-badge>
    </template>
    <span slot="payment_status" slot-scope="text">
          <a-tag :color="paymentStatuses.find(x => x.status === text).color">
          {{ paymentStatuses.find(x => x.status === text).text }}
          </a-tag>
        </span>
    <template slot="footer">
      {{ pagination.total }} položek
    </template>
  </a-table>
</template>

<script>
import { getTelephoneNumberFormatted } from '@/services/telephoneNumberFormatter'

export default {
  name: 'my-table',
  props: {
    defaultOrder: {
      type: String,
      default: 'desc',
    },
    selectMultiple: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
    columns: Array,
    dataSource: Array,
    idName: String,
    detailUrl: String,
    tableName: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAllSelected: false,
      sortOrder: undefined,
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      trigger: false,
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      const selections = []
      if (this.selectMultiple) {
        selections.push({
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            this.$store.commit('myTable/SET_KEYS', {
              name: this.tableName,
              items: this.selectedRowKeys.length === 0 ? this.dataSource.map(item => item[this.idName]) : [],
            })
            this.trigger = !this.trigger
            this.isAllSelected = this.selectedRowKeys.length !== 0
          },
        })
      }
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        columnWidth: 50,
        hideDefaultSelections: true,
        selections: selections,
        type: this.selectMultiple ? 'checkbox' : 'radio',
        onSelection: this.onSelection,
      }
    },
    pagination: function () {
      return this.$store.getters['pagination/getPagination'](this.tableName)
    },
    selectedRowKeys: function () {
      if (this.trigger) {
      }
      return this.$store.getters['myTable/getSelectedRowKeys'](this.tableName)
    },
    statuses: function () {
      return this.$store.getters['order/getStatuses']
    },
    trackStatuses: function () {
      return this.$store.getters['order/getTrackStatuses']
    },
    paymentStatuses: function () {
      return this.$store.getters['order/getPaymentStatuses']
    },
  },
  methods: {
    getTelephoneNumberFormatted,
    handleTableChange(pagination, filters, sorter) {
      let fetchParams
      if (!(sorter.order === undefined && (sorter.field === undefined || sorter.field.localeCompare(this.idName)))) {
        fetchParams = {
          results: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          filters: filters,
        }
      } else {
        fetchParams = {
          results: pagination.pageSize,
          page: pagination.current,
          sortField: this.idName,
          sortOrder: this.defaultOrder,
          filters: filters,
        }
      }
      this.$emit('fetch', fetchParams)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    onSelectChange(selectedRowKeys) {
      this.$store.commit('myTable/SET_KEYS', { name: this.tableName, items: selectedRowKeys })
      this.trigger = !this.trigger
    },
    toLocaleDate: function (text) {
      return this.$options.filters.formatDate(text)
    },
    confirmDelete(params) {
      this.$emit('confirmDelete', params)
    },
    showNote(text) {
      this.$emit('showNote', text)
    },
  },
  created() {
    this.$emit('fetch')
  },
  watch: {
    isAllSelected(newVal) {
      this.$emit('changeIsAllSelected', newVal)
    },
    selectedRowKeys(newVal) {
      this.$emit('changeSelectedRowKeys', newVal)
    },
  },
}
</script>

<style scoped>

</style>
