export function getTelephoneNumberFormatted(value) {
  const matches = value.match(/^\(([^)]+)\)(\d{3})(\d{0,3})(\d*)$/)
  if (matches && matches.length > 1) {
    let phone = '+' + matches[1] + ' ' + matches[2]
    for (let index = 3; index < matches.length; index++) {
      phone += ' ' + matches[index]
    }
    return phone
  }
  return value
}
