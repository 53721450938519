<template>
  <a-list-item v-if="doesntBelong()">
    <a-alert showIcon message="Error!" type="error">
      <div slot="description">
        Komentář nepatří této položce! Pro zobrazení komentáře klikněte <router-link class="utils__link--underlined utils__link" :to="getNewLink()">zde</router-link>.
      </div>
    </a-alert>
  </a-list-item>
  <a-list-item :class="highlighted ? 'list-item-highlight': ''" v-else-if="highlighted || activeComment === null || activeComment.cmt_id !== node.cmt_id">
    <div slot="extra">
      <a-dropdown placement="bottomRight">
        <a-button icon="down">Akce</a-button>
        <a-menu slot="overlay" @click="clickOnMenu($event, node)">
          <a-menu-item v-if="user.uer_id === node.uer_id" :key="0">
            <a-icon type="edit" class="mr-2"/>Upravit
          </a-menu-item>
          <a-menu-item v-if="canBan(node)" :key="2" @click="clickShowModal(node)">
            <a-icon type="stop" class="mr-2"/>Zabanovat uživatele
          </a-menu-item>
          <a-menu-item :key="1">
            <a-popconfirm @confirm="deleteComment(node, parent)">
              <template slot="title">
                <p>Opravdu si přejete odstranit tuto položku?</p>
              </template>
              <a-icon type="delete" class="mr-2"/>Odstranit
            </a-popconfirm>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <a-row type="flex" justify="start" :gutter="30">
      <a-col :md="6" :lg="6" :xl="6" :xs="24">
        <div class="list-avatar-user text-center">
          <div>
            <cui-avatar
              :src="node.user && node.user.avatar ? $avatarVectorPath + node.user.avatar : '/resources/images/avatar.jpg'"
              :size="50"
              :link="node.user && node.user.uer_type === 'AMR' ? '/user/admin/detail/' + node.user.uer_id : node.user && node.user.uer_type === 'RID' ? '/user/registered/detail/' + node.user.uer_id : ''"
              :bordered="false"
              :svg="!!(node.user && node.user.avatar)"
            />
          </div>
          <a-tag color="green" class="mb-2" v-if="activeUserId !== null && activeUserId === node.uer_id">Uživatel #{{ node.uer_id }}</a-tag>
          <div v-else-if="node.user" class="mb-2">
            <template v-if="node.user.uer_type === 'AMR'">
              <a-tag color="red" v-if="node.user.uer_id === user.uer_id">Ty</a-tag>
              <a-tag color="blue" v-else>{{node.user.uer_first_name + ' ' + node.user.uer_last_name}}</a-tag>
            </template>
            <strong v-else>{{node.user.email === null ? node.user.ip_addresses.length === 0 ? node.user.uer_session_id : node.user.ip_addresses[0].ias_ip_address : node.user.email}}</strong>
          </div>
          <small class="d-block text-muted">Vytvořeno: {{$options.filters.formatDate(node.created_at)}}</small>
          <small v-if="node.created_at !== node.updated_at" class="text-muted d-block">Aktualizováno: {{ node.updated_at | formatDate }}</small>
        </div>
      </a-col>
      <a-col :md="14" :lg="14" :xl="16" :xs="24" class="list-body-with-actions">
        <div v-if="node.cmt_message !== null" class="mt-4">
          <div v-if="quoteParent" class="mb-3">
            <router-link class="utils__link--underlined" v-if="node.put_id" :to="'/ecommerce/product/detail/' + node.put_id">{{ node.product.history_languages_without_pivot[0].phe_name }}</router-link>
            <router-link class="utils__link--underlined" v-else :to="'/blog/detail/' + node.blg_id">{{ node.blog.languages_without_pivot[0].ble_name }}</router-link>
            <blockquote class="blockquote mt-3" v-if="node.parent">
              <p class="mb-0">{{ node.parent.cmt_message }}</p>
              <footer class="blockquote-footer">
                Uživatel <router-link class="utils__link--underlined utils__link--blue" :to="'/user/registered/detail/' + node.parent.uer_id">#{{ node.parent.uer_id }}</router-link>
              </footer>
            </blockquote>
          </div>
          <div v-if="update !== node.cmt_id">
            {{node.cmt_message}}
          </div>
          <div v-else>
            <a-textarea :autoSize="{ minRows: 2, maxRows: 10 }" placeholder="Upravit komentář..." v-model="update_message"></a-textarea>
            <a-button :disabled="loading || update_message === ''" :loading="loading" class="mt-2 mr-2" type="primary" @click="handleUpdateComment(node, update_message)">
              Upravit
            </a-button>
            <a-button @click="cancelUpdate">Zrušit</a-button>
          </div>
        </div>
        <div class="ant-list-item-action-buttons">
          <a-button :disabled="loading" :loading="loading" class="ant-btn-default" type="link" v-if="node.children_count > 0 && (node.show === undefined || node.show === false)" @click="handleLoadChildrenComment(node)">
            <a-icon type="message"></a-icon>
            {{ node.children_count }}
          </a-button>
          <a-button class="ant-btn-default" type="link" @click="showReplyInput(node)">
            <ion-icon name="arrow-undo-outline" class="mr-2"></ion-icon>
            Odpovědět
          </a-button>
        </div>
        <div v-if="replies === node.cmt_id">
          <a-textarea :autoSize="{ minRows: 2, maxRows: 10 }" placeholder="Napište odpověď..." v-model="cmt_message"></a-textarea>
          <a-button :disabled="loading || cmt_message === ''" :loading="loading" class="mt-2 mr-2" type="primary" @click="handleReplyToComment(node, cmt_message)">
            Odpovědět
          </a-button>
          <a-button @click="cancel">Zrušit</a-button>
        </div>
      </a-col>
    </a-row>
    <div
      v-if="node.children && node.children.length > 0"
      class="ant-list-item-children"
    >
      <list-tree-node
        v-for="child in node.children.filter(x => x.deleted === undefined)"
        :node="child"
        :key="child.cmt_id"
        :delete-comment="deleteComment"
        :reply-to-comment="replyToComment"
        :quote-parent="false"
        :loading="loading"
        :parent="node"
        :active-user-id="activeUserId"
        :click-show-modal="clickShowModal"
        :is-user-banned="isUserBanned"
        :load-children-replies="loadChildrenReplies"
        :update-comment="updateComment"
      ></list-tree-node>
    </div>
  </a-list-item>
</template>

<script>
import CuiAvatar from '@/components/CleanUIComponents/Avatar'
export default {
  components: {
    CuiAvatar,
  },
  name: 'ListTreeNode',
  props: {
    highlighted: Boolean,
    quoteParent: Boolean,
    node: Object,
    parent: Object,
    loading: Boolean,
    activeUserId: Number,
    deleteComment: Function,
    replyToComment: Function,
    updateComment: Function,
    isUserBanned: {
      type: Function,
    },
    loadChildrenReplies: Function,
    clickShowModal: Function,
  },
  data() {
    return {
      update_message: '',
      cmt_message: '',
      replies: null,
      update: null,
      trigger: false,
      count: 0,
    }
  },
  computed: {
    user: function() {
      return this.$store.getters['auth/getItem']
    },
    activeComment: function () {
      return this.$store.getters['comment/getDetail']
    },
  },
  methods: {
    cancel() {
      this.replies = null
      this.cmt_message = ''
    },
    showReplyInput(comment) {
      this.replies = comment.cmt_id
    },
    clickOnMenu(e, item) {
      if (e.key === 0) {
        this.update = item.cmt_id
        this.update_message = item.cmt_message
      }
    },
    handleReplyToComment(node, message) {
      this.replyToComment(node, message)
        .then(() => {
          this.replies = null
          this.cmt_message = ''
        })
    },
    handleUpdateComment(node, updateMessage) {
      this.updateComment(node, updateMessage)
        .then(() => {
          this.update = null
          this.update_message = ''
        })
    },
    handleLoadChildrenComment(node) {
      this.loadChildrenReplies(node)
        .then(() => {
          this.trigger = !this.trigger
        })
    },
    canBan(node) {
      if (node.user && node.user.uer_type === 'AMR') {
        return false
      }
      return this.user.uer_id !== node.uer_id && !this.isUserBanned(node)
    },
    cancelUpdate() {
      this.update = null
      this.update_message = ''
    },
    doesntBelong() {
      const id = Number.parseInt(this.$route.params.id)
      return (this.$route.name === 'blog.detail.comments.detail' && id !== this.node.blg_id) ||
        (this.$route.name === 'product.detail.comments.detail' && id !== this.node.put_id)
    },
    getNewLink() {
      if (this.node.put_id !== null) {
        return { name: 'product.detail.comments.detail', params: { id: this.node.put_id, commentId: this.node.cmt_id } }
      }
      return { name: 'blog.detail.comments.detail', params: { id: this.node.blg_id, commentId: this.node.cmt_id } }
    },
  },
  updated() {
    if (this.count < 2 && this.highlighted) {
      this.$nextTick(() => {
        this.$el.scrollIntoView()
        this.count++
      })
    }
  },
  watch: {
    activeComment(newVal, oldVal) {
      if (this.highlighted) {
        this.$nextTick(() => {
          this.$el.scrollIntoView()
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
